import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useSetAtom } from 'jotai';

import { gql, useMutation } from '@apollo/client';

import { Button, Icon, InputAdornment, Stack, TextField, Typography, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';

import Link from '@mui/material/Link'


import Loading from '../../../../components/core/data-fetch/Loading';

import PasswordTextField from '../../join-link/blocks/PasswordTextField';
import { JoinLinkCard, JoinPersonAvatar } from '../../join-link/blocks/JoinStylingComponents';

import { authTokenAtom } from '../../../../store/authTokenAtom';

const SIGNUP_AND_JOIN_GROUP_MUTATION = gql`
  mutation SignupAndJoinGroup($joinCode: String!, $username: String!, $email: String!, $password: String!) {
    signup_and_join_group(joinCode: $joinCode, username: $username, email: $email, password: $password) {
      token
    }
  }
`;

const useStyles = makeStyles((theme) => ({

  usernameField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  emailField: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  icon: {
    color: '#0000008a'
  },

  policyText: {
    color: '#0000008a',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  studentAccountText: {
    color: '#f5821f',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight: 700,
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  }
}));

const INDIVIDUAL_SUBSCRIPTION_JOIN_CODE = "AABBGG01KKK";


const UnderLinedAnchor = styled(`a`)({
  textDecorationLine: 'underline'
});


function Signup2Panel({
  username, email, password,
  usernameOnChange, usernameValidate, usernameErrorMessage,
  passwordOnChange, passwordValidate, passwordErrorMessage,
  emailOnChange, emailValidate, emailErrorMessage,
  usernameInUse, emailInUse, networkErrorGotoPanel }) {

  const navigate = useNavigate();


  const [signup, { loading, error }] = useMutation(SIGNUP_AND_JOIN_GROUP_MUTATION, {
    variables: {
      joinCode: INDIVIDUAL_SUBSCRIPTION_JOIN_CODE,
      username,
      email,
      password
    },
    onCompleted: (data) => handleSignupCompleted(data)
  });

  useEffect(() => {
    if (error && error.graphQLErrors && error.graphQLErrors.length) {
      error.graphQLErrors.map(({ code, field }) => {
        switch (code) {
          case 4000:
            if (field === "username") {
              usernameInUse();
            } else if (field === "email") {
              emailInUse();
            }
            break;
          default:
            networkErrorGotoPanel();
        }
      })
    }
  }, [error, usernameInUse, networkErrorGotoPanel]);

  const setAuthToken = useSetAtom(authTokenAtom);

  const classes = useStyles();

  const handleSignupCompleted = (data) => {
    const { token } = data.signup_and_join_group;

    setAuthToken(token);

    navigate('/ws');
  }

  const handleSignup = () => {
    let errorMessage = '';
    errorMessage = usernameValidate();
    if (errorMessage === '') {
      errorMessage = emailValidate();
    }
    if (errorMessage === '') {
      errorMessage = passwordValidate();
    }
    if (errorMessage === '') {
      signup();
    }
  }


  if (loading) return <Loading />;

  return (
    <JoinLinkCard title={
      <Stack direction="row" alignItems="center">
        <Icon sx={{ color: '#FFF' }}>
          <SchoolIcon />
        </Icon>
        <Typography variant="h6" ml={2}>Student Sign Up</Typography>
      </Stack>
    }>
      <Stack direction="column" sx={{ height: { xs: 450, md: 'auto' }, overflow: 'auto', px: 2 }}>
        <Typography variant="h6" className={classes.studentAccountText}>
          For a teacher account, please contact us at <Link color="primary" href="mailto:info@enlighted.app">info@enlighted.app</Link>.
        </Typography>
        <TextField
          className={classes.usernameField}
          placeholder="username"
          type="text"
          value={username}
          error={usernameErrorMessage !== ''}
          onChange={usernameOnChange}
          onBlur={usernameValidate}
          autoFocus
          helperText={usernameErrorMessage ? usernameErrorMessage : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon className={classes.icon} />
              </InputAdornment>
            )
          }}
        />
        <TextField
          className={classes.emailField}
          placeholder="user@email.com"
          type="email"
          error={emailErrorMessage !== ''}
          onChange={emailOnChange}
          onBlur={emailValidate}
          helperText={emailErrorMessage ? emailErrorMessage : ''}
          value={email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon className={classes.icon} />
              </InputAdornment>
            )
          }}
        />
        <PasswordTextField
          password={password}
          errorMessage={passwordErrorMessage}
          onChange={passwordOnChange}
          validate={passwordValidate}
          autoFocus={false}
        />
        <Typography className={classes.policyText}>
          By clicking Sign Up you agree to the Enlight Ed&#39;s <UnderLinedAnchor target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1EMb7nfom6cGH1vK8U5o6Tg6BN6mfLu8Q/view">Terms and Conditions </UnderLinedAnchor> and <UnderLinedAnchor target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1gQ1Rw71J5ryMikay1Uxc8Zna5Mkip3dx/view"> Privacy Policy</UnderLinedAnchor>.
        </Typography>
        <Button variant="contained" onClick={handleSignup} sx={{ color: '#FFF', mb: 2 }}>Sign up</Button>
      </Stack>
    </JoinLinkCard >)
}

export default Signup2Panel;