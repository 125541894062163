import React from 'react';

import AccountSuspendedForHackMessage from '../../../components/core/messages/AccountSuspendedForHackMessage'

import { useParams } from 'react-router-dom';

function SignoutPage() {

  const params = useParams();

  return (
    <>
      {(+params.errorCode === 3000) && <AccountSuspendedForHackMessage />}
    </>
  )
}


export default SignoutPage;
