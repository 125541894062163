import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Divider, Icon, InputAdornment, List, ListItem, Stack, TextField, Typography } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';

import Error from '../../../../components/core/data-fetch/Error';
import { useDefaultMode } from '../../../../components/core/context/ApplicationContextHandler';


import { JoinLinkCard } from '../../join-link/blocks/JoinStylingComponents';
import SPSigninCard from './SPSigninCard';
import SPSigninButton from '../actions/SPSigninButton';


const useStyles = makeStyles((theme) => ({

  usernameField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },

  personIcon: {
    color: '#f5821f'
  },
  title: {
    color: '#0000008a',
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    color: "#f5821f"
  },
  listItem: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

}));


function SPSigninPanel({ groupCard, joinCode }) {

  const [username, setUsername] = useState('')
  const [usernameErrorMessage, setUserNameErrorMessage] = useState('');
  const [error, setError] = useState({});

  const classes = useStyles();

  useDefaultMode();

  function internalUsernameValidate(event) {
    validateField(event.target)
  }

  function validateField(prop) {
    const { name, value } = prop;

    let errorMessage = ""

    // user name validation
    if (name === "username") {
      if (!value || value === "") {
        errorMessage = "Please provide your name"
      }
    }

    setUserNameErrorMessage(errorMessage);
    const isValid = errorMessage === "";
    return isValid;
  }

  function validateFields() {

    const isValidUserName = validateField({ name: "username", value: username })

    return isValidUserName
  }

  const usernameOnChange = ({ target }) => setUsername(target.value);

  return (
    <JoinLinkCard
      title={
        <Stack direction="row" alignItems="center">
          <Icon sx={{ color: '#FFF' }}>
            <SchoolIcon />
          </Icon>
          <Typography variant="h6" ml={2}>Welcome</Typography>
        </Stack>
      }>
      <List>
        <ListItem className={classes.listItem}>
          <SPSigninCard name={groupCard.subjectName} iconName="fa-book" />
        </ListItem>
        <Divider />
        <ListItem className={classes.listItem}>
          <SPSigninCard name={groupCard.teacherName} iconName="fa-chalkboard-teacher" />
        </ListItem>
        <Divider />
      </List>
      <TextField
        className={classes.usernameField}
        placeholder="name"
        type="text"
        value={username}
        error={usernameErrorMessage !== ''}
        onChange={usernameOnChange}
        onBlur={internalUsernameValidate}
        autoFocus
        helperText={usernameErrorMessage ? usernameErrorMessage : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon className={classes.personIcon} />
            </InputAdornment>
          )
        }} />

      <SPSigninButton username={username} joinCode={joinCode} validateFields={validateFields} setError={setError} />
    </JoinLinkCard>
  );
}


export default SPSigninPanel;