import React, { useEffect, useRef } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useSetAtom } from 'jotai';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Icon, IconButton, Stack, styled, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';

import Loading from '../../../../components/core/data-fetch/Loading';

import { ID_JOIN_BACK_TO_EMAIL, JoinLinkCard, JoinPersonAvatar } from '../blocks/JoinStylingComponents';
import PasswordResetLink, { ID_FORGOT_PASSWORD } from '../blocks/PasswordResetLink';
import PasswordTextField from '../blocks/PasswordTextField';

import { authTokenAtom } from '../../../../store/authTokenAtom';

const SIGNIN_AND_JOIN_GROUP_MUTATION = gql`

  mutation SignInAndJoinGroup($groupId: Int!, $email: String!, $password: String!) {
    signin_and_join_group(groupId: $groupId, email: $email, password: $password) {
      token
      join_status
    }
  }
`;

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  color: '#FFF',
  backgroundColor: theme.palette.blue.main,
  '&:hover': {
    backgroundColor: theme.palette.blue.dark
  }
}));


const JOIN_STATUS_JOIN_SUCCESSFUL = 1000;
const JOIN_STATUS_ALREADY_PART_OF_GROUP = 2000;

function JoinLoginPanel({ groupId, email, password, emailGotoPanel, passwordOnChange, passwordValidate, incorrectCredentials, passwordErrorMessage, joinSummaryGotoPanel, accountSuspendedGotoPanel, networkErrorGotoPanel }) {

  const [signin, { loading, error }] = useMutation(SIGNIN_AND_JOIN_GROUP_MUTATION, {
    variables: {
      groupId,
      email,
      password
    },
    onCompleted: (data) => handleSignInCompleted(data)
  });

  useEffect(() => {
    if (error && error.graphQLErrors && error.graphQLErrors.length) {
      error.graphQLErrors.map(({ code }) => {
        switch (code) {
          case 1000:
            accountSuspendedGotoPanel();
            break;
          case 2000:
            incorrectCredentials();
            break;
          default: networkErrorGotoPanel();
        }
      })
    }
  }, [error, accountSuspendedGotoPanel, incorrectCredentials, networkErrorGotoPanel]);


  const navigate = useNavigate();

  const loginButton = useRef(null);

  const setAuthToken = useSetAtom(authTokenAtom);

  const handleSignInCompleted = (data) => {
    const { join_status, token } = data.signin_and_join_group;

    setAuthToken(token);

    if (join_status === JOIN_STATUS_ALREADY_PART_OF_GROUP) {
      navigate('/ws');
    } else if (join_status === JOIN_STATUS_JOIN_SUCCESSFUL) {
      joinSummaryGotoPanel();
    }
  }

  const handleLogin = () => {
    const errorMessage = validate();
    if (errorMessage === '') {
      signin();
    }
  }

  const validate = (event) => {
    // Skip username validation if the user wants to sign up
    const errorMessage = '';
    if (event && event.relatedTarget) {
      const eventId = event.relatedTarget.id;
      if (ID_FORGOT_PASSWORD === eventId || ID_JOIN_BACK_TO_EMAIL === eventId) {
        return errorMessage;
      }
    }
    return passwordValidate();
  }


  if (loading) return <Loading />;

  return (
    <JoinLinkCard
      title={
        <Box sx={{ position: 'relative' }}>
          <IconButton onClick={emailGotoPanel} id={ID_JOIN_BACK_TO_EMAIL} sx={{ maxWidth: 200, position: 'absolute', }}>
            <ArrowBackIcon fontSize="large" sx={{ color: '#FFF', position: 'absolute', top: 1, bottom: 1, left: 5 }} />
          </IconButton>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Icon sx={{ color: '#FFF' }}>
              <SchoolIcon />
            </Icon>
            <Typography variant="h6" ml={2}>Login As Existing User</Typography>
          </ Stack>
        </Box>
      }>
      <JoinPersonAvatar />
      <Stack direction='row' justifyContent="center" spacing={1} m={2}>
        <EmailIcon color="primary" />
        <Typography variant="subtitle2" color="primary" >{email}</Typography>
      </Stack>
      <Stack direction='column'>
        <PasswordTextField
          password={password}
          errorMessage={passwordErrorMessage}
          onChange={passwordOnChange}
          validate={validate}
          autoFocus={true}
          onKeyDown={(event) => {
            if (event.key == 'Enter') {
              if (loginButton.current) {
                loginButton.current.click();
              }
            }
          }}
        />
        <StyledButton variant="contained" onClick={handleLogin} ref={loginButton}>Login</StyledButton>
        <PasswordResetLink />
      </Stack>
    </JoinLinkCard>)
}

export default JoinLoginPanel;