import React, { useEffect } from 'react';

import { useSetAtom } from 'jotai';

import { clearStorage } from '../../../features/auth/signout/Signout'
import { useApolloClient } from '@apollo/client';

import { useNavigate } from "react-router-dom";

import AccountSuspendedMessage from '../messages/AccountSuspendedMessage'
import InvalidCredentialsMessage from '../messages/InvalidCredentialsMessage'
import NetworkErrorMessage from '../messages/NetworkErrorMessage'
import LoginWithSurfMessage from '../messages/LoginWithSurfMessage'
import { Box } from '@mui/material';

import { authTokenAtom } from '../../../store/authTokenAtom';

function ResetAuthToken() {

  const setAuthToken = useSetAtom(authTokenAtom);

  useEffect(() => {
    setAuthToken("");
  }, []);

  return null;
}



function SignOut(props) {

  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {


    navigate(`/sign-out/${props.code}`)
    clearStorage(client);

  }, [client, navigate, props.code])
  return null;
}


function NetworkErrorsHandler(props) {
  const { error } = props
  const isNetworkError = error.networkError && error.networkError.message && error.networkError.statusCode;
  if (!isNetworkError) return null;

  return (
    <NetworkErrorMessage />
  )
}

function UserInputErrorMessage(props) {
  const { field, code, assignErrorToFields } = props

  useEffect(() => {
    assignErrorToFields(code, field)
    // TODO: review wht assignErrorToFields function is different between renders
  }, [field, code])

  return <div />
}

function GraphQLErrorHandler(props) {
  const { error, assignErrorToFields } = props
  const hasGraphQLErrors = error.graphQLErrors && error.graphQLErrors.length;
  if (!hasGraphQLErrors) return null;

  const errorComponents =
    error.graphQLErrors.map(({ code, field }, i) => {
      switch (code) {
        case 999: return (< NetworkErrorMessage key={i} />);
        case 1000: return (<AccountSuspendedMessage key={i} />);
        case 2000: return (<InvalidCredentialsMessage key={i} />);
        case 2100: return (<ResetAuthToken key={i} />);
        case 3000: return (<SignOut {...props} key={i} />)
        case 4000: return (<UserInputErrorMessage key={i} field={field} assignErrorToFields={assignErrorToFields} code={4000} />)
        case 4100: return (<UserInputErrorMessage key={i} field={field} assignErrorToFields={assignErrorToFields} code={4100} />)
        case 4200: return (<UserInputErrorMessage key={i} field={field} assignErrorToFields={assignErrorToFields} code={4200} />)
        case 4300: return (<UserInputErrorMessage key={i} field={field} assignErrorToFields={assignErrorToFields} code={4300} />)
        case 5000: return (<LoginWithSurfMessage key={i} />);

        case 9000: return (<UserInputErrorMessage key={i} field={field} assignErrorToFields={assignErrorToFields} code={9000} />)
        default: return (< NetworkErrorMessage key={i} />);
      }
    })

  return errorComponents;
}

// Render runtime errors, including GraphQL errors and network errors.
//
// The error passed as a prop to this component is an Apollo Client
// 'QueryResult' object that has 'graphQLErrors' and 'networkError' properties.
function Error(props) {
  const { error } = props
  if (!error) return null

  return (
    <Box my={1}>
      <NetworkErrorsHandler {...props} />
      <GraphQLErrorHandler {...props} />
    </Box>
  )
}


export default Error;
