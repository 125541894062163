import React, { useEffect } from 'react';

import { Button, styled } from '@mui/material';
import { gql, useLazyQuery } from '@apollo/client';


const GET_ACCOUNT_STATUS_FOR_GROUP = gql`
  query GetAccountStatusForGroup($groupId: Int!, $email: String!) {
    accountStatusForGroup(groupId: $groupId, email: $email)
  }
`;

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  color: '#FFF',
  backgroundColor: theme.palette.blue.main,
  '&:hover': {
    backgroundColor: theme.palette.blue.dark
  }
}));


function JoinEmailNextStep({ groupId, email, emailOnChange, emailValidate, emailInvalidForGroup, joinTrialGroup, accountFound, accountNotFound, networkErrorGotoPanel }) {

  const [getAccountStatus, { error }] = useLazyQuery(
    GET_ACCOUNT_STATUS_FOR_GROUP, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => handleAccountStatus(data)
  });

  useEffect(() => {
    if (error) {
      networkErrorGotoPanel();
    }
  }, [error, networkErrorGotoPanel]);


  const handleAccountStatus = (data) => {

    switch (data.accountStatusForGroup) {
      case 1000:
        joinTrialGroup();
        break;
      case 2000:
        emailInvalidForGroup();
        break;
      case 3000:
        accountFound();
        break;
      case 4000:
        accountNotFound();
        break;
    }

  }

  const onClick = () => {
    const errorMessage = emailValidate();

    if (errorMessage === '') {
      const normEmail = email.trim().toLowerCase();
      emailOnChange({ target: { value: normEmail } });

      getAccountStatus({
        variables: {
          groupId,
          email: normEmail
        }
      })
    }
  }

  return (
    <StyledButton variant="contained" onClick={onClick} >Next</StyledButton>
  );
}

export default JoinEmailNextStep;