import React from 'react';

import { Typography } from '@mui/material';

import LandingPage from '../app-layout/LandingPage';

function NotFound() {
  const sourceUrl = window.location.hash ? window.location.hash.substring(2) : "";

  return (<LandingPage>
    <Typography variant='h6' color="textSecondary">Sorry, no matching page for <code>{sourceUrl}</code></Typography>
  </LandingPage>);

}

export default NotFound;
