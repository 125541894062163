import { Card, CardContent, CardHeader, styled } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  margin: '20px',
  [theme.breakpoints.up('md')]: {
    width: '500px',
  },
  [theme.breakpoints.down('md')]: {
    width: '400px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '320px',
  },
  backgroundColor: '#f5f5f5',
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.blue.main,
  color: 'white',
}));

const StyledCardContent = styled(CardContent)({
  paddingLeft: '10px',
  backgroundColor: 'white',
});

export { StyledCard, StyledCardHeader, StyledCardContent }