import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AppBar } from '@mui/material';

import { appBarHeightAtom } from '../../../store/appLayoutAPI';

function useAppBarHeight() {
  const [appBarHeight, setAppBarHeight] = useAtom(appBarHeightAtom);
  const theme = useTheme();
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let newAppBarHeight = 56;

    if (isLandscape) {
      newAppBarHeight = 48;
      if (!isSmallScreen) {
        newAppBarHeight = 64;
      }
    } else if (!isSmallScreen) {
      newAppBarHeight = 64;
    }

    if (newAppBarHeight !== appBarHeight) {
      setAppBarHeight(newAppBarHeight);
    }
  }, [isLandscape, isSmallScreen, appBarHeight, setAppBarHeight]);

  return appBarHeight;
}

function StyledAppBar({ children }) {
  const appBarHeight = useAppBarHeight();

  return (<AppBar position="static" sx={{ backgroundColor: '#515151', height: `${appBarHeight}px` }}>
    {children}
  </AppBar>);
}

export default StyledAppBar;