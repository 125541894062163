
export const getTestCenterUrl = (testSize, testType) => {
  if (testType === 'practice') {
    let idx = 0;
    if (testSize === 30) {
      idx = 2;
    } else if (testSize === 18) {
      idx = 1;
    }
    return `/ws/practice-tests/${idx}`;
  }

  if (testType === 'timed-practice') {
    let idx = 0;
    if (testSize === 25) {
      idx = 2;
    } else if (testSize === 15) {
      idx = 1;
    }
    return `/ws/timed-practice-tests/${idx}`;
  }

  if (testType === 'pretest') {
    return `/ws/pretests/`;
  }

  return '';
}