import React, { useState } from 'react';

import { IconButton, InputAdornment, TextField } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PasswordIcon from '@mui/icons-material/Lock'

const EMPTY_FUN = () => {}


function PasswordTextField({ password, errorMessage, onChange, validate, autoFocus, fullWidth, onKeyDown = EMPTY_FUN }) {


    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((sp) => !sp)
    }

    return (
        <TextField
            sx={
              (theme) => ({
                marginBottom: theme.spacing(3),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                width: fullWidth ? "100%" : "auto"
              })
            }
            placeholder="password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            error={errorMessage !== ''}
            onChange={onChange}
            onBlur={validate}
            autoFocus={autoFocus}
            helperText={errorMessage ? errorMessage : ''}
            onKeyDown={onKeyDown}
            variant="filled"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon sx={ {color: '#0000008a'} } />
                    </InputAdornment>
                  ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                        >
                            {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
                }}
            />
    )
}
export default PasswordTextField;