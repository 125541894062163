import React from 'react'

import LandingPage from '../../../components/core/app-layout/LandingPage';
import { JoinLinkMessage } from '../join-link/blocks/JoinStylingComponents';

function UserNotFound() {
  return (
    <LandingPage>
      <JoinLinkMessage
        title="User not found"
        details="Ask your teacher for a join link to create an account." />
    </LandingPage>
  )
}

export default UserNotFound;