import React from 'react';

import { CircularProgress, Dialog } from '@mui/material';

function LoadingDialog() {

    return (
        <Dialog
            open={true}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
                }}>
            <div style={{display:'flex', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', margin: '5px'}}>
                <CircularProgress size={80} disableShrink/>
            </div>
        </Dialog>);
}

export default LoadingDialog;