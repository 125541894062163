import React, { useContext } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useSetAtom } from 'jotai';

import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';

import { ApplicationContext } from '../../../../components/core/context/ApplicationContext';

import Loading from '../../../../components/core/data-fetch/Loading';
import { authTokenAtom } from '../../../../store/authTokenAtom';


const SCHOOL_PRACTICE_SIGNIN_MUTATION = gql`
  mutation SchoolPracticeSignIn($fullName: String!, $joinCode: String!) {
    school_practice_signin(fullName: $fullName, joinCode: $joinCode) {
      token
    }
  }
`;


const useStyles = makeStyles(() => ({

  button: {
    color: 'white',
    backgroundColor: '#f5821f',
  },
}));

function SPSigninButton({ username, joinCode, validateFields, setError }) {

  const appContext = useContext(ApplicationContext);

  const navigate = useNavigate();

  const location = useLocation();

  const setAuthToken = useSetAtom(authTokenAtom);

  const classes = useStyles();


  const [signin, { loading, error }] = useMutation(SCHOOL_PRACTICE_SIGNIN_MUTATION, {
    onCompleted: handleCompleted,
  })


  function handleCompleted(data) {

    const { token } = data.school_practice_signin;

    // console.log(`saving sign-out url: ${JSON.stringify(props.location)}`)
    appContext.setSignoutRedirect(`${location.pathname}${location.search}`);
    // console.log('handleCompleted called');
    setAuthToken(token);

    navigate(`/school-practice`);
  }


  const onClick = (e) => {
    e.preventDefault();

    const areFieldsValid = validateFields();

    if (areFieldsValid) {
      signin({
        variables: {
          fullName: username,
          joinCode
        }
      });
    }
  }

  if (error) {
    setError(error);
  }
  if (loading) return <Loading />;

  return (<Button
    className={classes.button}
    variant="contained"
    onClick={onClick}>
    Log in
  </Button>);
}

export default SPSigninButton;