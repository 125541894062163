
import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";


/////////////////////////////////////////////////////////////////////////////////////////
///                            Auth Token                                            ////
/////////////////////////////////////////////////////////////////////////////////////////

const storage = createJSONStorage(() => sessionStorage)

export const authTokenAtom = atomWithStorage('authToken', "", storage, { getOnInit: true });

export const isAuthenticatedAtom = atom(
  (get) => {
    const token = get(authTokenAtom);
    return token !== "";
  }
)