import { atom } from "jotai";
import { focusAtom } from "jotai-optics";

import { setupTeacherGroups, storeDataAtom } from "./internal/storedata";
import { CURRENT_SUBJECT_ID_KEY, jotaiStore } from "./store";

/////////////////////////////////////////////////////////////////////////////////////////
///                            Subject Specific Queries                              ////
/////////////////////////////////////////////////////////////////////////////////////////

export const currentSubjectAtom = focusAtom(storeDataAtom, (optic) => optic.prop('currentSubject'));

export const subjectsForUserAtom = focusAtom(storeDataAtom, (optic) => optic.prop('subjects'))

export const currentSubjectIdAtom = atom(
  (get) => {
    const currentSubject = get(currentSubjectAtom);
    return currentSubject !== null ? currentSubject.id : null;
  }
);

export const currentLanguageCodeAtom = atom(
  (get) => {
    const currentSubject = get(currentSubjectAtom);
    return currentSubject !== null ? currentSubject.langCode : null;
  }
);

export const firstLessonOfCurrentSubjectAtom = atom(
  (get) => {
    const subject = get(currentSubjectAtom);

    return getFirstLesson(subject);
  }
);

export const subjectSupportsTestingAtom = atom(
  (get) => {
    const subject = get(currentSubjectAtom);
    // only the dutch rekentoets subject supports testing
    return subject.id === 4;
  });

export const subjectSupportsTipsAtom = atom(
  (get) => {
    const subject = get(currentSubjectAtom);
    // only the dutch rekentoets subject supports testing
    return subject.id === 4;
  });

export const selectSubjectForUser = (subjectId) => {
  const storeData = jotaiStore.get(storeDataAtom);

  const currentSubject = storeData.subjects.find((sub) => sub.id == subjectId);

  jotaiStore.set(storeDataAtom, {
    user: storeData.user,
    subjects: storeData.subjects,
    currentSubject: currentSubject,
    allTeacherGroups: storeData.allTeacherGroups,
    currentTeacherGroups: setupTeacherGroups(storeData.allTeacherGroups, currentSubject)
  });

  sessionStorage.setItem(CURRENT_SUBJECT_ID_KEY, subjectId);

  return getFirstLesson(currentSubject);
}

/////////////////////////////////////////////////////////////////////////////////////////
///                            Implementation                                        ////
/////////////////////////////////////////////////////////////////////////////////////////
const getFirstLesson = (subject) => {
  if (subject === null) {
    return null;
  }

  const domain = subject.domains[0];
  const subDomain = domain.subdomains[0];

  return subDomain.lessons[0];
}