import { atom, createStore } from 'jotai';

import { EMPTY_STORE_DATA, setupTeacherGroups, storeDataAtom } from './internal/storedata';
import { defaultWorkspaceTypeAtom, workspaceTypeAtom } from './workspaceAPI';
import { authTokenAtom } from './authTokenAtom';

/////////////////////////////////////////////////////////////////////////////////////////
///                       User & Subject Store                                        ///
////////////////////////////////////////////////////////////////////////////////////////
export const jotaiStore = createStore();

export const CURRENT_SUBJECT_ID_KEY = "currentSubjectId";

const NOT_AUTH_SUBJETC_ID = -1;

export const setupNotAuthUserWithLangCode = (langCode) => {
  const NOT_AUTH_EDIT_CAT_ID = -1;

  const user = {
    id: -1,
    defaultSubjectId: NOT_AUTH_SUBJETC_ID,
    subscription: "trial",
    type: "student",
    roles: "",
    editCategoryId: NOT_AUTH_EDIT_CAT_ID,
  }
  const subjects = [{
    id: NOT_AUTH_SUBJETC_ID,
    langCode: langCode,
    license: "paid",
    domains: [{
      subdomains: [{
        lessons: [{
          id: -1,
          name: "",
          code: ""
        }]
      }]
    }]
  }]
  const teacherGroups = [];
  setupCurrentUserWithSubject(user, subjects, NOT_AUTH_SUBJETC_ID, teacherGroups);
}

export const isAuthStoreReadyAtom = atom(
  (get) => {
    const storeData = get(storeDataAtom);
    const defaultWorkspace = get(defaultWorkspaceTypeAtom);

    return (!isEmptyObject(storeData.user)
      && !isEmptyObject(storeData.subjects) && !isEmptyObject(storeData.currentSubject) && (storeData.currentSubject !== undefined) 
      && (defaultWorkspace !== null) && (storeData.currentTeacherGroups !== null));
  }
)

export const setupCurrentUserAndSubjects = (user, subjectsForUser, teacherGroups) => {
  // If sessionStorage contains a valid subjectId for this user use it
  const currentSubjectId = sessionStorage.getItem(CURRENT_SUBJECT_ID_KEY);
  if (currentSubjectId && currentSubjectId != NOT_AUTH_SUBJETC_ID ) {
    setupCurrentUserWithSubject(user, subjectsForUser, currentSubjectId, teacherGroups);
    return;
  }

  // If there is a defaultSubject definition
  if (user && user.defaultSubjectId) {
    setupCurrentUserWithSubject(user, subjectsForUser, user.defaultSubjectId, teacherGroups)
    return;
  }

  // else use first subject
  setupCurrentUserWithSubject(user, subjectsForUser, subjectsForUser[0].id, teacherGroups)
}


export const clearAuthSore = () => {
  jotaiStore.set(storeDataAtom, EMPTY_STORE_DATA);
  jotaiStore.set(workspaceTypeAtom, "");
  jotaiStore.set(authTokenAtom, "");
}

///////////////////////////////////////////////////////////////////////////////////////////
///                     Implementation                                                 ////
///////////////////////////////////////////////////////////////////////////////////////////


const setupCurrentUserWithSubject = (user, subjectsForUser, subjectId, teacherGroups) => {
  const currentSubject = subjectsForUser.find((sub) => sub.id == subjectId);

  jotaiStore.set(storeDataAtom, {
    user: user,
    subjects: subjectsForUser,
    currentSubject: currentSubject,
    allTeacherGroups: teacherGroups,
    currentTeacherGroups: setupTeacherGroups(teacherGroups, currentSubject)
  });

  sessionStorage.setItem(CURRENT_SUBJECT_ID_KEY, subjectId);

}


const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0;


