import React from 'react'

import AppErrorMesage from './AppErrorMessage';

function AccountSuspendedForHackMessage() {
  return (
    <AppErrorMesage message="Your subscription does not allow to practice this exercise. Your account has been suspended." />
  );
}

export default AccountSuspendedForHackMessage;