import { atomWithStorage, createJSONStorage, selectAtom } from "jotai/utils";
import { atom } from "jotai";

import { jotaiStore } from "./store";
import { focusAtom } from "jotai-optics";

/////////////////////////////////////////////////////////////////////////////////////////
///                            Implementation                                        ////
/////////////////////////////////////////////////////////////////////////////////////////

const examStore = createJSONStorage(() => sessionStorage);

const EMPTY_EXAM_DATA = {
  examId: -1,
  exerciseCount: 0,
  timeInterval: 0,
  isExamRunning: false,
  currentExerciseIdx: 0,
}

const examDataAtom = atomWithStorage('examData', EMPTY_EXAM_DATA, examStore, { getOnInit: true });

const readOnlyExamDataAtom = atom((get) => get(examDataAtom));

const settableCurrExerciseIdxAtom = focusAtom(examDataAtom, (optic) => optic.prop('currentExerciseIdx'));

const settableIsExamRunningAtom = focusAtom(examDataAtom, (optic) => optic.prop('isExamRunning'));

/////////////////////////////////////////////////////////////////////////////////////////
///                       Exam API                                                   ////
/////////////////////////////////////////////////////////////////////////////////////////


export const startExam = (examId, exerciseCount, isPretest) => {
  jotaiStore.set(examDataAtom, {
    examId: examId,
    exerciseCount: exerciseCount,
    timeInterval: 0,
    isExamRunning: true,
    currentExerciseIdx: 1,
    isPretest: isPretest,
    isTimedExam: false,
  });
}

export const startTimedExam = (examId, exerciseCount, timeInterval) => {
  jotaiStore.set(examDataAtom, {
    examId: examId,
    exerciseCount: exerciseCount,
    timeInterval: timeInterval,
    isExamRunning: true,
    currentExerciseIdx: 1,
    isTimedExam: true,
  });
}

export const incExerciseIdxIfNeededForExam = () => {
  const examData = jotaiStore.get(readOnlyExamDataAtom);
  const currIdx = examData.currentExerciseIdx;
  const exerciseCount = examData.exerciseCount;
  jotaiStore.set(settableCurrExerciseIdxAtom, currIdx === exerciseCount ? currIdx : currIdx + 1);
}

export const stopExam = () => {
  jotaiStore.set(settableIsExamRunningAtom, false);
}


export const stopTimeOutExam = (examId) => {
  if (jotaiStore.get(examIdAtom) === examId) {
    jotaiStore.set(settableIsExamRunningAtom, false);
  }
}

export const examIdAtom = selectAtom(readOnlyExamDataAtom, (s) => s.examId);

export const exerciseCountAtom = selectAtom(readOnlyExamDataAtom, (s) => s.exerciseCount);

export const currExerciseIdxAtom = selectAtom(readOnlyExamDataAtom, (s) => s.currentExerciseIdx);

export const isExamRunningAtom = selectAtom(readOnlyExamDataAtom, (s) => s.isExamRunning);

export const timerIntervalAtom = selectAtom(readOnlyExamDataAtom, (s) => s.timeInterval);

export const isExamTimerRunningAtom = selectAtom(readOnlyExamDataAtom, (s) => s.timeInterval && s.isExamRunning);

export const isPretestAtom = selectAtom(readOnlyExamDataAtom, (s) => s.isPretest);

export const isTimedExamAtom = selectAtom(readOnlyExamDataAtom, (s) => s.isTimedExam);
