import React, { useContext } from 'react';

import { useAtomValue } from 'jotai';

import { useNavigate } from 'react-router-dom';

import { ApolloConsumer } from '@apollo/client'

import { Button, styled } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { ApplicationContext } from '../../../components/core/context/ApplicationContext';

import { clearAuthSore } from '../../../store/store';
import { currentLanguageCodeAtom } from '../../../store/subjectAPI';

function clearStorage(client) {
  localStorage.removeItem('auth-token');

  localStorage.removeItem("exerciseId")
  localStorage.removeItem("isInEditor")
  localStorage.removeItem("egIds")
  localStorage.removeItem("egStartDetails")
  localStorage.removeItem("signoutRedirect")

  localStorage.removeItem("selectedLessonId")
  localStorage.removeItem("problemScrollIndex")

  sessionStorage.removeItem("workspaceType");
  sessionStorage.removeItem("currentSubjectId");

  client.resetStore();

}


const StyledButton = styled(Button)({
  padding: 0,
  margin: 0,
  textTransform: "capitalize"
})

function Signout({ inAppHeader }) {

  const appContext = useContext(ApplicationContext);
  const { signoutRedirect, setSignoutRedirect } = appContext;

  const navigate = useNavigate();

  const currentLanguageCode = useAtomValue(currentLanguageCodeAtom);

  function handleClick(client) {

    clearStorage(client);

    const signoutUrl = signoutRedirect && signoutRedirect !== '' ? signoutRedirect : '/sign-in';

    setSignoutRedirect('');

    clearAuthSore();


    navigate(signoutUrl);
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const nonAppHeaderLabel = currentLanguageCode === 'nl' ? "Afmelden" : "Sign Out";

        const label = inAppHeader ? "Sign Out" : nonAppHeaderLabel;

        return (
          <StyledButton
            onClick={() => handleClick(client)}
            startIcon={<LogoutIcon />}
            sx={{
              color: inAppHeader ? '#FFF' : (theme) => theme.palette.blue.main,
              width: inAppHeader ? 'auto' : '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 1
            }}>
            {label}
          </StyledButton>)
      }
      }
    </ApolloConsumer>
  );
}

export { clearStorage, Signout }
