import React from 'react';

import StyledAppBar from './StyledAppBar';
import { Container, Toolbar } from '@mui/material';
import AppHomeIcon from './AppHomeIcon';

function HomeHeader() {

  return (
    <StyledAppBar position="static" >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <a href='https://enlighted.app'>
            <AppHomeIcon sx={{ display: 'flex' }} />
          </a>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}

export default HomeHeader;
