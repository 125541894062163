import React from 'react';

import { Icon, InputAdornment, Stack, TextField, Typography } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';

import JoinEmailNextStep from '../actions/JoinEmailNextStep';
import { JoinLinkCard, JoinPersonAvatar } from '../blocks/JoinStylingComponents';


function JoinEmailPanel({ accountFound, accountNotFound, emailInvalidForGroup, joinTrialGroup, groupId, email, emailOnChange, emailValidate, emailErrorMessage, networkErrorGotoPanel }) {

  const title = "Welcome to Enlight-ed!";

  return (
    <JoinLinkCard title={
      <Stack direction="row" alignItems="center">
        <Icon sx={{ color: '#FFF' }}>
          <SchoolIcon />
        </Icon>
        <Typography variant="h6" ml={2}>{title}</Typography>
      </Stack>
    }>
      <Stack direction='column' height="100%" spacing={4}>
        <JoinPersonAvatar />

        <Stack direction="column" spacing={1} sx={{ px: 2 }} >
          <Typography variant='subtitle1' color='textSecondary'>Enter your email address </Typography>

          <TextField
            sx={{ mb: 3 }}
            label="Email"
            placeholder="user@institute.nl"
            type="email"
            error={emailErrorMessage !== ''}
            onChange={emailOnChange}
            onBlur={emailValidate}
            autoFocus
            helperText={emailErrorMessage ? emailErrorMessage : ''}
            value={email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              )
            }}
          />
        </Stack>
        <JoinEmailNextStep
          email={email}
          emailOnChange={emailOnChange}
          emailValidate={emailValidate}
          groupId={groupId}
          emailInvalidForGroup={emailInvalidForGroup}
          joinTrialGroup={joinTrialGroup}
          accountFound={accountFound}
          accountNotFound={accountNotFound}
          networkErrorGotoPanel={networkErrorGotoPanel} />
      </Stack>
    </JoinLinkCard >)
}

export default JoinEmailPanel;