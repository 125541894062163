import { ID_NOT_LOADED } from "../context/ApplicationContext";


function buildExerciseListLink(authorSandboxId, appContext, subjectId) {
  const selectedLessonId = appContext.selectedLessonId;

  if (!authorSandboxId || authorSandboxId === '') {
    return (selectedLessonId === ID_NOT_LOADED || selectedLessonId === '')
            ? '/select-assignment'
            : `/select-assignment/${subjectId}/${selectedLessonId}`;
  } else {
    return `/select-assignment/${subjectId}/${authorSandboxId}`;
  }
}


export { buildExerciseListLink }