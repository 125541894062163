import React from 'react';

import { Link } from '@mui/material';


export const ID_FORGOT_PASSWORD = "1000";


function PasswordResetLink() {

  const cl = window.location;
  const passwordResetUrl = cl.port
    ? `${cl.protocol}//${cl.hostname}:${cl.port}/password-reset/new`
    : `${cl.protocol}//${cl.hostname}/password-reset/new`;


  return (
    <Link variant="subtitle2" href={passwordResetUrl} id={ID_FORGOT_PASSWORD} sx={{ mr: 2 }}>
      Forgot password?
    </Link>);
}

export default PasswordResetLink;