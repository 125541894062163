import { atom } from "jotai";

import { jotaiStore } from "./store";
import { currentUserAtom } from "./internal/storedata";

/////////////////////////////////////////////////////////////////////////////////////////
///                   Workspace Type Tracking & Update                               ////
/////////////////////////////////////////////////////////////////////////////////////////
export const workspaceTypeAtom = atom(
  (get) => get(overwrittenWorkspaceType) || get(defaultWorkspaceTypeAtom),
  (get, set, update) => set(overwrittenWorkspaceType, capClientSideType(get(defaultWorkspaceTypeAtom), update))
)


export const showSwitchWorkspaceForStudentAtom = atom(
  (get) => get(defaultWorkspaceTypeAtom) !== "student"
)

export const resetWorkspaceType = () => jotaiStore.set(overwrittenWorkspaceType, null);

///////////////////////////////////////////////////////////////////////////////////////////
///                     Implementation                                                 ////
///////////////////////////////////////////////////////////////////////////////////////////

const capClientSideType = (clientType, serverType) => {
  if (serverType === "student") {
    return "student";
  }
  if (serverType === "teacher") {
    return clientType === "student" ? "student" : "teacher";
  }
  if (serverType === "author") {
    return clientType === "student" || clientType === "teacher" ? clientType : "author";
  }
  return serverType;
}


export const defaultWorkspaceTypeAtom = atom(
  (get) => {
    const currentUser = get(currentUserAtom);
    return currentUser !== null ? currentUser.type : null;
  }
);

// const WORKSPACE_TYPE_KEY = "workspaceType";
const overwrittenWorkspaceType = atom(null);

