import React from 'react'

import AppErrorMesage from './AppErrorMessage';

function NetworkErrorMessage() {
  return (
    <AppErrorMesage message="The server encountered an error and could not complete your request. Please try again later." />
  );
}

export default NetworkErrorMessage
