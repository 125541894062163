import React from 'react'

import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    iconContainer: {
        // color:'#0000008a',
        color: "#009ddc",
        marginRight: theme.spacing(2),
    },
  }));

function SPSigninCard(props) {

    const { iconName, name } = props;

    const iconClass = `fas ${iconName} text-3xl`;
    const classes = useStyles();

    return (
        <Grid container direction='row' alignItems='center' wrap='nowrap'>
            <div className={classes.iconContainer}>
                <i className={iconClass} />
            </div>
            <Typography variant='h5' style={{color: "#009ddc", fontWeight: 500}}>{name}</Typography>
        </Grid>
    )
}

export default SPSigninCard;