import React, { useEffect, useRef, useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { Box, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';

import Error from '../../../../components/core/data-fetch/Error';
import { useDefaultMode } from '../../../../components/core/context/ApplicationContextHandler';

import PasswordTextField from '../../join-link/blocks/PasswordTextField';
import PasswordResetLink, { ID_FORGOT_PASSWORD } from '../../join-link/blocks/PasswordResetLink';

import { EmptySignInFooter, HorizontalLineWithOr, SignUpFooter } from './SignInBlocks';

import SignIn from '../actions/SignIn';
import ExternalAuthenticate from '../actions/ExternalAuthenticate';


export const ID_SIGNUP_LINK_FROM_SIGN_IN = "signup_link_from_sign_in";


function SigninForm({ sessionExpired }) {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);

  const [errors, setErrors] = useState({});

  const signInButton = useRef(null);

  useDefaultMode();

  useEffect(() => {
    setLoginError(null);

  }, [username, password]);


  const cl = window.location;

  function handleBlur(event) {
    // Skip username validation if the user wants to sign up
    if (event.relatedTarget && (ID_SIGNUP_LINK_FROM_SIGN_IN === event.relatedTarget.id || ID_FORGOT_PASSWORD === event.relatedTarget.id)) {
      return;
    }
    validateField(event.target)
  }


  function validateField(prop) {
    const { name, value } = prop;

    let errorMessage = ""

    // user name validation
    if (name === "username") {
      if (!value || value === "") {
        errorMessage = "Please provide a username or email address."
      }
    }

    // password validation
    if (name === "password") {
      if (!value || value === "") {
        errorMessage = "Please provide your password."
      }
    }


    setErrors(pErrors => ({
      ...pErrors,
      [name]: errorMessage
    }));
    const isValid = errorMessage === "";
    return isValid;
  }

  function validateFields() {

    const isValidUserName = validateField({ name: "username", value: username })
    const isValidPassword = validateField({ name: "password", value: password })

    return isValidUserName && isValidPassword
  }

  const handleUserNameChange = ({ target }) => {
    setUsername(target.value);
    setErrors((errors) => { errors["username"] = ''; return errors });
  }

  const validate = (event) => {
    // Skip username validation if the user wants to sign up
    const errorMessage = '';
    if (event && event.relatedTarget) {
      const eventId = event.relatedTarget.id;
      if (ID_SIGNUP_LINK_FROM_SIGN_IN !== eventId && ID_FORGOT_PASSWORD !== eventId) {
        return errorMessage;
      }
    }
  }
  const enableSignUp = !sessionExpired && cl.hostname && (cl.hostname.includes("practice") || cl.hostname.includes("localhost"));

  const inlineStyle = sessionExpired ? {} : { margin: (theme) => theme.spacing(2) };

  return (
    <>
      <Paper elevation={5} sx={inlineStyle}>
        <Stack direction="column">
          <Box pt={2}>
            <Typography sx={
              {
                // color: '#515151',
                textAlign: 'center',
                width: '100%',
                fontWeight: 600,
              }
            } variant="h4" color="primary">Welcome</Typography>
          </Box>

          <Box pt={2} px={4} mb={2}>
            <ExternalAuthenticate provider="surfconext" />
          </Box>

          <HorizontalLineWithOr />

          <form noValidate >

            <Box px={{ xs: 1, md: 2 }}>
              {loginError && (<Error error={loginError} />)}
              <TextField
                sx={
                  (theme) => ({
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(3),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    width: '100%'
                  })
                }
                placeholder="username or email"
                type="text"
                value={username}
                error={("username" in errors) && errors["username"] !== ''}
                onChange={handleUserNameChange}
                onBlur={handleBlur}
                autoFocus
                helperText={errors["username"] ? errors["username"] : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon sx={{ color: '#0000008a' }} />
                    </InputAdornment>
                  )
                }}
                variant="filled"
              />
              <PasswordTextField
                password={password}
                errorMessage={errors["password"] ? errors["password"] : ''}
                onChange={({ target, key }) => setPassword(target.value)}
                onKeyDown={(event) => {
                  if (event.key == 'Enter') {
                    if (signInButton.current) {
                      signInButton.current.click();
                    }
                  }
                }}
                validate={validate}
                fullWidth
              />
              <Box textAlign="right">
                <PasswordResetLink />
              </Box>
            </Box>

            <Box pt={2} px={4} mb={3}>
              <SignIn
                username={username}
                password={password}
                validateFields={validateFields}
                setError={setLoginError}
                buttonRef={signInButton}
                sessionExpired={sessionExpired} />
            </Box>
          </form>
          <Box sx={
            {
              marginBottom: 3,
              height: '100%',
              width: '100%'
            }
          } >
            {enableSignUp && (<SignUpFooter />)}
            {!enableSignUp && (<EmptySignInFooter />)}
          </Box>
        </Stack>
      </Paper >
    </>
  );
}


export default SigninForm;
