import React, { Component } from 'react';

import { HashRouter as Router, Route, Routes, Outlet } from 'react-router-dom';

import { CssBaseline, Stack, styled } from '@mui/material';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { darken, lighten } from '@mui/material/styles';


import { MathfieldElement } from 'mathlive/dist/mathlive.js';

const ExercisesLandingPage = React.lazy(() => import('./components/exercise-overview/ExercisesLandingPage'));

const ReportsLandingPage = React.lazy(() => import('./features/reports/ReportsLandingPage'));

const JoinLandingPage = React.lazy(() => import('./features/auth/join-link/JoinLandingPage'));

const SolveExercise = React.lazy(() => import('./components/chat/SolveExercise'));

const DeprecatedSignupPage = React.lazy(() => import('./features/auth/signup/DeprecatedSignupPage'));

import Signup2LandingPage from './features/auth/signup/Signup2LandingPage';
import SetupAuthenticatedUser from './features/auth/signin/SetupAuthenticatedUser';

import SigninLandingPage from './features/auth/signin/SigninLandingPage';
import SPSigninLandingPage from './features/auth/signin-school-practice/SPSigninLandingPage';

import SignoutPage from './features/auth/signout/SignoutPage';

import NotFound from './components/core/pages/NotFound';

const ExerciseEditor = React.lazy(() => import('./components/exercise-editor/ExerciseEditor'));

const ExampleExercise = React.lazy(() => import('./features/public-exercises/ExampleExercise'));

const UserWorkspace = React.lazy(() => import('./workspaces/UserWorkspace'));

const ImageGalleryPage = React.lazy(() => import('./components/gallery/ImageGalleryPage'));


import Header from './components/core/app-layout/Header';

import Loading from './components/core/data-fetch/Loading';

import { ID_NOT_LOADED, ApplicationContext } from './components/core/context/ApplicationContext';

import './App.css';
import UserNotFound from './features/auth/signin/UserNotFound';
import { appBarHeightAtom } from './store/appLayoutAPI';
import { useAtomValue } from 'jotai';


const WorkspaceContainer = styled(Stack)({
  height: '100vh',
  width: '100vw',
  margin: 0,
  padding: 0,
  backgroundColor: '#EFEFEF',
  flexDirection: 'column',
  overflow: 'hidden'
});

const MainPanelContainer = styled(`main`,
  { shouldForwardProp: (prop) => prop !== 'appBarHeight' }
)(({ appBarHeight, theme }) => ({
  flexDirection: 'row',
  height: `calc(100% - ${appBarHeight}px)`,
  width: '100%',
  margin: 0,
  padding: theme.spacing(1),
  display: 'flex'
}));

function FullPageLayout() {

  const appBarHeight = useAtomValue(appBarHeightAtom);

  return (
    <WorkspaceContainer>
      <Header />
      <MainPanelContainer appBarHeight={appBarHeight}>
        <Outlet />
      </MainPanelContainer>
    </WorkspaceContainer>
  )
}

function OwnHeaderLayout() {
  return (
    <WorkspaceContainer>
      <Outlet />
    </WorkspaceContainer>
  )
}

const RED_MAIN = '#e03a3e';
const YELLOW_MAIN = '#fdb827';
const BLUE_MAIN = '#4B6DBA';

class App extends Component {

  constructor(props) {
    super(props);

    // math-live stopped working without this registration
    // get call returns undefined even when definition is available
    if (!window.customElements.get('math-field-element')) {
      try {
        window.customElements.define('math-field-element', MathfieldElement);
      } catch (error) {
        // ignore if it was registered
      }
    }

    this.theme = responsiveFontSizes(createTheme(
      {
        palette: {
          primary: {
            main: '#f5821f',
          },
          secondary: {
            main: '#a0a0a0',
            contrastText: '#fff'
          },
          red: {
            main: RED_MAIN,
            light: lighten(RED_MAIN, 0.2),
            dark: darken(RED_MAIN, 0.3),
            contrastText: '#fff'
          },
          yellow: {
            main: YELLOW_MAIN,
            light: lighten(YELLOW_MAIN, 0.2),
            dark: darken(YELLOW_MAIN, 0.3),
            contrastText: '#fff'
          },
          blue: {
            main: BLUE_MAIN,
            light: lighten(BLUE_MAIN, 0.2),
            dark: darken(BLUE_MAIN, 0.3),
            contrastText: '#fff'
          }

        },
        overrides: {
          MuiTableRow: {
            hover: {
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04) !important"
              },
            }
          }
        }
      }));

    this.setAuthoredExerciseContext = (exerciseId) => {
      this.setState({
        exerciseId,
      });

      localStorage.setItem("exerciseId", exerciseId);
    }


    this.setIsInEditor = (isInEditor) => {
      this.setState({
        isInEditor,
      })

      localStorage.setItem("isInEditor", isInEditor);
    }

    this.setSignoutRedirect = (signoutRedirect) => {
      this.setState({
        signoutRedirect,
      })

      localStorage.setItem("signoutRedirect", signoutRedirect);
    }

    this.setProblemScrollIndex = (problemScrollIndex) => {
      this.setState({
        problemScrollIndex,
      })

      localStorage.setItem("problemScrollIndex", problemScrollIndex);
    }

    this.setSelectedLessonId = (selectedLessonId) => {
      this.setState({
        selectedLessonId,
      })

      localStorage.setItem("selectedLessonId", selectedLessonId);
    }

    this.setSelectedLessonType = (selectedLessonType) => {
      this.setState({
        selectedLessonType,
      })

      localStorage.setItem("selectedLessonType", selectedLessonType);
    }

    this.setNewlyAddedProblemId = (newlyAddedProblemId) => {
      this.setState({
        newlyAddedProblemId,
      })

    }

    this.setIsInTemplateView = (isInTemplateView) => {
      this.setState({
        isInTemplateView,
      })
    }

    this.loadIDFromStorage = (key) => {
      const strValue = localStorage.getItem(key);
      let value = ((!strValue) || strValue === "NaN") ? ID_NOT_LOADED : this.getFromStorageAsNumber(key);
      if (!value) {
        value = ID_NOT_LOADED;
      }
      return value;
    }

    this.state = {
      selectedLessonId: this.loadIDFromStorage("selectedLessonId"),
      selectedLessonType: localStorage.getItem("selectedLessonType"),
      problemScrollIndex: this.getFromStorageAsNumber("problemScrollIndex"),

      exerciseId: this.getFromStorageAsNumber("exerciseId"),
      signoutRedirect: localStorage.getItem("signoutRedirect"),

      setAuthoredExerciseContext: this.setAuthoredExerciseContext,
      setIsInEditor: this.setIsInEditor,
      setSignoutRedirect: this.setSignoutRedirect,
      setProblemScrollIndex: this.setProblemScrollIndex,
      setSelectedLessonId: this.setSelectedLessonId,
      setSelectedLessonType: this.setSelectedLessonType,
      setIsInTemplateView: this.setIsInTemplateView,
      setNewlyAddedProblemId: this.setNewlyAddedProblemId,
    }

    this.getFromStorageAsNumber = this.getFromStorageAsNumber.bind(this);
  }

  getFromStorageAsNumber(key) {
    const str = localStorage.getItem(key);
    const result = str && parseInt(str, 10);
    return result;
  }


  render() {
    return (
      <>
        <CssBaseline />
        <ThemeProvider theme={this.theme}>
          <ApplicationContext.Provider value={this.state}>
            <Router>
              <Routes>
                <Route element={<OwnHeaderLayout />}>
                  <Route
                    path="/ws/*"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <UserWorkspace />
                      </React.Suspense>
                    }
                  />
                </Route>
                <Route element={<FullPageLayout />}>
                  <Route exact path="/" element={<SigninLandingPage />} />
                  <Route exact path="/sign-out/:errorCode" element={<SignoutPage />} />



                  <Route
                    exact
                    path="/exampleexercise/:exerciseIdx"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <ExampleExercise />
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/select-assignment"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <ExercisesLandingPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/select-assignment/:subjectId/:lessonId"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <ExercisesLandingPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/school-practice"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <ExercisesLandingPage schoolPracticeLandingPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/school-practice/:subjectId/:lessonId"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <ExercisesLandingPage schoolPracticeLandingPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/reports"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <ReportsLandingPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/solve/:exerciseId"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <SolveExercise />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/sign-up"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <DeprecatedSignupPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/setup-auth-user"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <SetupAuthenticatedUser />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/user-not-found"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <UserNotFound />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/sign-up2"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <Signup2LandingPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path={SIGN_IN}
                    element={<SigninLandingPage />}
                  />

                  <Route
                    path="/sign-in-school-practice/:code"
                    element={<SPSigninLandingPage />}
                  />

                  <Route
                    path="/sign-in-school-practice"
                    element={<SPSigninLandingPage />}
                  />

                  <Route
                    path="/editor/:problemId/:propTabIdx"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <ExerciseEditor />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/gallery"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <ImageGalleryPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/join/:code"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <JoinLandingPage />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/join"
                    element={
                      <React.Suspense fallback={<Loading />}>
                        <JoinLandingPage />
                      </React.Suspense>
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </Router>
          </ApplicationContext.Provider>
        </ThemeProvider >
      </>
    );
  }
}

export const SIGN_IN = '/sign-in';
export const NO_AUTH_ROUTES = ['/', SIGN_IN, '/join', '/sign-up', '/sign-up2', '/exampleexercise, /sign-out, /sign-in-school-practice', '/user-not-found'];

export default App;
