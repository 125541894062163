import React from 'react';

import { useAtomValue } from 'jotai';

import { workspaceTypeAtom } from '../../../store/workspaceAPI';
import { currentUserAtom } from '../../../store/internal/storedata';


function hasUserRole(requiredRole, userType, userRoles, userId, editCategory, resourceAuthorId) {
  // admins are superusers
  if (userType === 'admin') {
    return true;
  }

  if (!userRoles) {
    return false;
  }

  // console.log("checking role: ".concat(role))
  if (userRoles.includes('admin')) {
    return true
  }

  if (requiredRole.includes('author:self') && userType === "author") {
    // console.log(
    //   'author:self found with userId'.concat(userId).
    //     concat(' and authorId ').concat(resourceAuthorId).concat('res ').concat(userId === resourceAuthorId))
    // console.log(`type of authorId: ${typeof resourceAuthorId}, type of userId: ${typeof userId}`);
    if (userId === resourceAuthorId) {
      return true;
    }
  }
  if (requiredRole.includes('author:share') && userRoles.includes('author:share') && userType === "author") {
    // console.log(
    //   'author:self found with userId'.concat(userId).
    //     concat(' and authorId ').concat(resourceAuthorId).concat('res ').concat(userId === resourceAuthorId))
    // console.log(`type of authorId: ${typeof resourceAuthorId}, type of userId: ${typeof userId}`);
    if (userId === resourceAuthorId) {
      return true;
    }
  }
  if (requiredRole.includes('author:createself') && userType === "author") {
    if (editCategory === resourceAuthorId) {
      return true;
    }
  }
  if (requiredRole.includes('author:generate') && userType === "author" && userRoles.includes('author:generate')) {
    if (editCategory === resourceAuthorId) {
      return true;
    }
  }

  // console.log("user roles is ".concat(userRoles))
  const res = requiredRole.split(',').find(
    (rl) => {
      return ((rl !== 'author:self') && (rl !== 'author:authorself') && (rl !== 'author:share') && (rl !== 'author:generate') && userRoles.includes(rl));
    }
  );
  // console.log(`role check result: ${res !== undefined}`)
  return (res !== undefined)
}

function ValidateRole(props) {

  const user = useAtomValue(currentUserAtom);

  const workspaceType = useAtomValue(workspaceTypeAtom);

  const hasRole = hasUserRole(props.requiredRole, workspaceType, user.roles, user.id,
    user.editcategoryId, props.authorId);
  if (hasRole) {
    // console.log(`result of validation in validaterole: required: ${props.requiredRole} assigned: ${user.roles}`)
    return props.children
  } else {
    // console.log(`result of validation in validaterole: ${hasRole}`)
    return null
  }

}

export default ValidateRole;
export { hasUserRole }
