import React, { useState } from 'react'

import { useAtomValue } from 'jotai';

import { useNavigate } from 'react-router-dom';

import { Select, MenuItem, Button } from '@mui/material';

import SubjectIcon from '@mui/icons-material/Subject';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { makeStyles } from '@mui/styles';

import { currentSubjectAtom, selectSubjectForUser, subjectsForUserAtom } from '../../../store/subjectAPI';



const useStyles = makeStyles(theme => ({
  select: {
    color: 'white',
    fontWeight: 600,
    paddingTop: '0.5rem',
    borderBottom: '2px solid #f5821f',
  },
  button: {
    color: 'white',
    fontFamily: 'Lato',
    fontWeight: 600,
  },
}));

function SubjectSelector() {

  const currentSubject = useAtomValue(currentSubjectAtom);

  const subjects = useAtomValue(subjectsForUserAtom);

  const navigate = useNavigate();


  const [open, setOpen] = useState(false);


  const classes = useStyles();

  const handleChange = (event) => {
    event.preventDefault();
    const subjectId = +event.target.value;

    if (currentSubject.id !== subjectId) {
      const firstLesson = selectSubjectForUser(subjectId);
      navigate(`/select-assignment/${subjectId}/${firstLesson.id}`);
    }
  }

  // Do not render a selection for a single subject
  if ( currentSubject && currentSubject.id === null || currentSubject.id === -1) return false;


  return (
    <>
      {!open && <Button
        className={classes.button}
        onClick={() => setOpen(true)}
        startIcon={<SubjectIcon/>}>
          {currentSubject.name}
          <ExpandMore/>
      </Button>}
      {open && (<Select
          value={currentSubject.id}
          onChange={handleChange}
          open={true}
          classes={{select: classes.select}}
          variant='filled' autoWidth
          IconComponent={() => (<ExpandLess style={{color: 'white'}}/>)}>
          {subjects.map(({ id, name }) => (
            <MenuItem className={classes.menuItem} key={id} value={id} onClick={() => setOpen(false)}>
              {name}
            </MenuItem>
          ))}
        </Select>)}
    </>
  );
}

export default SubjectSelector;