import { atom, useAtomValue } from "jotai";

import { workspaceTypeAtom } from "./workspaceAPI";

import { hasUserRole } from "../components/core/auth/ValidateRole";
import { currentUserAtom } from "./internal/storedata";
import { authorSandboxIdAtom } from "./userAPI";


/////////////////////////////////////////////////////////////////////////////////////////
///                            Role Specific Queries                                 ////
/////////////////////////////////////////////////////////////////////////////////////////

export const hasTeacherInTrainingRoleAtom = atom(
  (get) => {
    const currentUser = get(currentUserAtom);
    const workspaceType = get(workspaceTypeAtom);
    if (!workspaceType || !currentUser) {
      return false;
    }

    const isTeacherInTraining = workspaceType === "author" && currentUser.roles && currentUser.roles.includes("teacher:school-practice-analytics");
    const isTeacherInTrainingSupervisor = workspaceType === "author" && currentUser.roles && currentUser.roles.includes("author:self,author:createself,author:readany,teacher:analytics,teacher:manage-all-groups");
    return isTeacherInTraining || isTeacherInTrainingSupervisor;
  }
)

export const hasAnalyticsRoleAtom = atom(
  (get) => {
    const currentUser = get(currentUserAtom);
    const workspaceType = get(workspaceTypeAtom);
    if (!workspaceType || !currentUser) {
      return false;
    }

    const hasTeacherInTrainingRole = get(hasTeacherInTrainingRoleAtom);


    const isAuthorWithAnalytics = workspaceType === "author" && currentUser.roles && currentUser.roles.includes("teacher:analytics");

    return hasTeacherInTrainingRole || isAuthorWithAnalytics;
  }
)

export const hasTemplateEditingRoleAtom = atom(
  (get) => {
    const currentUser = get(currentUserAtom);
    const workspaceType = get(workspaceTypeAtom);
    if (!workspaceType || !currentUser) {
      return false;
    }

    return hasUserRole("author:templates", workspaceType, currentUser.roles, currentUser.id,
      currentUser.editcategoryId, "");

  }
)

export const hasSchoolPracticeRoleAtom = atom(
  (get) => {
    const currentUser = get(currentUserAtom);
    const workspaceType = get(workspaceTypeAtom);
    if (!workspaceType || !currentUser) {
      return false;
    }

    return currentUser.type !== "admin" && hasUserRole("student:school-practice", workspaceType, currentUser.roles, currentUser.id,
      currentUser.editcategoryId, "");

  })

export const hasExerciseEditingRole = (lessonId) => {

  const authorSandboxId = useAtomValue(authorSandboxIdAtom);
  const currentUser = useAtomValue(currentUserAtom);
  const workspaceType = useAtomValue(workspaceTypeAtom);

  return hasUserRole("author:createany, author:createself", workspaceType, currentUser.roles, currentUser.id,
    authorSandboxId, lessonId);
}



