import React, { useEffect } from 'react'

import { useAtom } from 'jotai';

import { useNavigate } from 'react-router-dom';

import LandingPage from '../../../components/core/app-layout/LandingPage';

import { JoinLinkMessage } from '../join-link/blocks/JoinStylingComponents';

import { authTokenAtom } from '../../../store/authTokenAtom';


function NonAuthenticatedUserMessage() {
  return (
    <LandingPage>
      <JoinLinkMessage
        title="External signin error"
        details="Please try again later." />
    </LandingPage>
  )
}

function SetupAuthenticatedUser() {

  const [authToken, setAuthToken] = useAtom(authTokenAtom);

  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      setAuthToken(token);
      navigate('/ws');
    }
  }, []);

  if (!authToken) {
    return (
      <NonAuthenticatedUserMessage />
    )
  }
  return null;
}


export default SetupAuthenticatedUser;