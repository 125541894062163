import React from 'react';

import { Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';


function TestExercise({ exerciseId }) {

  const navigate = useNavigate();

  const testExercise = () => {
    navigate(`/solve/${exerciseId}`);
  }

  return (
    <Button
      onClick={testExercise}>
      Test Exercise
    </Button>
  )

}

export default TestExercise;
