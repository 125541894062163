import React from 'react';

import { gql, useMutation } from '@apollo/client';

import { Box, Button } from '@mui/material';

import Error from '../../../../components/core/data-fetch/Error';
import Loading from '../../../../components/core/data-fetch/Loading';

const AUTH_MUTATION = gql`
  mutation Auth($provider: String!) {
    auth(provider: $provider) {
      url
    }
  }
`;

function SvgIcon() {
  return (
    <Box sx={
      {
        height: '35px',
        display: 'block',
        paddingRight: (theme) => theme.spacing(2),
      }}>
      <img
        style={{ height: '35px', width: 'auto', display: 'block' }}
        src="/images/surfconext.svg"
        alt="SURFconext" />
    </Box>
  );
}


function ExternalAuthenticate(props) {

  function handleCompleted({ auth }) {
    window.location = auth.url;
  }

  const [auth, { loading, error }] = useMutation(AUTH_MUTATION,
    {
      variables: { provider: props.provider },
      onCompleted: handleCompleted
    });

  const onClick = (e) => {
    e.preventDefault();
    auth();
  }

  if (loading) return <Loading />;
  if (error) return <Error error={error} />


  return (
    <Button
      sx={
        {
          color: 'white',
          backgroundColor: (theme) => theme.palette.blue.main,
          padding: (theme) => theme.spacing(2),
          width: '100%',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.blue.dark,
          },
          outline: 'none',
          '&:focus': {
            outline: 'none'
          }
        }
      }
      size="large"
      variant="contained"
      onClick={onClick}
      startIcon={<SvgIcon />}
    >
      LOG IN WITH SURFconext
    </Button>
  );
}

export default ExternalAuthenticate;
