import React, { useEffect } from 'react';

import { gql, useApolloClient, useMutation } from '@apollo/client';

import { useNavigate } from 'react-router-dom';

import { useSetAtom } from 'jotai';

import { Button } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';

import LoadingDialog from '../../../../components/core/data-fetch/LoadingDialog';


import { authTokenAtom } from '../../../../store/authTokenAtom';

export const SIGNIN_MUTATION = gql`
  mutation SignIn($username: String!, $password: String!) {
    signin(username: $username, password: $password) {
      token
    }
  }
`;


function SignIn({ username, password, validateFields, setError, buttonRef, sessionExpired }) {

  useEffect(() => {
    if (error) {
      setError(error);
      reset();
    }
  });

  const navigate = useNavigate();

  const setAuthToken = useSetAtom(authTokenAtom);

  const client = useApolloClient();

  const handleCompleted = async (data) => {
    const { token } = data.signin;
    setAuthToken(token);
    await client.resetStore();

    if (!sessionExpired) {
      navigate('/ws');
    }
  }


  const [signin, { loading, error, reset }] = useMutation(SIGNIN_MUTATION, {
    variables: {
      username,
      password
    },
    onCompleted: handleCompleted
  });


  const handleSignIn = (e) => {
    e.preventDefault();
    const areFieldsValid = validateFields();

    if (areFieldsValid) {
      signin();
    }
  }


  if (loading) return <LoadingDialog />;

  return (
    <Button
      sx={
        {
          color: 'white',
          // backgroundColor: '#515151E0',
          // backgroundColor: (theme) => theme.palette.primary.main,
          width: '100%',
          padding: (theme) => theme.spacing(1.5),
          // '&:hover': {
          //   backgroundColor: '#707070',
          // },
          outline: 'none',
          '&:focus': {
            outline: 'none'
          }
        }
      }
      variant="contained"
      size="large"
      onClick={handleSignIn}
      startIcon={<PersonIcon />}
      ref={buttonRef}
      color="primary">
      Log in
    </Button>
  )
}

export default SignIn;