import {useEffect, useContext} from 'react';

import { useAtomValue } from 'jotai';

import { examIdAtom, stopExam } from '../../../store/examAPI';
import { stopTestCenterPracticeMode } from '../../../store/testCenterPracticeAPI';

import {ApplicationContext} from './ApplicationContext';

function useEditorMode(problemId ) {
    const appContext = useContext(ApplicationContext);

    const { setAuthoredExerciseContext, setIsInEditor  } = appContext;

    useEffect(() => {
        setIsInEditor(true);

        setAuthoredExerciseContext(problemId);

        stopTestCenterPracticeMode();

    }, [problemId, setAuthoredExerciseContext]);
}


function usePracticeMode() {
    const appContext = useContext(ApplicationContext);

    const { setIsInEditor, exerciseId } = appContext;

    const examId = useAtomValue(examIdAtom);

    useEffect(() => {
      if (examId !== null) {
        setIsInEditor(false);
      }
      if (exerciseId !== null) {
        setIsInEditor(false);
      }
    },[]);
}

function useDefaultMode() {
    const appContext = useContext(ApplicationContext);

    const { setAuthoredExerciseContext, setIsInEditor  } = appContext;

    useEffect(() => {
        setIsInEditor(false);
        setAuthoredExerciseContext("");
        stopExam();
        stopTestCenterPracticeMode();
    },[]);
}


export { useDefaultMode, useEditorMode, usePracticeMode  }