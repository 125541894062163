import React from 'react';

import { Avatar, Button, Icon, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';

import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';


import { StyledCard, StyledCardContent, StyledCardHeader } from '../../../common-ui/StyledCard';

export const ID_JOIN_BACK_TO_EMAIL = "2000";

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  color: '#FFF',
  backgroundColor: theme.palette.blue.main,
  '&:hover': {
    backgroundColor: theme.palette.blue.dark
  }
}));


function JoinLinkCard({ title, children }) {

  return (
    <StyledCard>
      <StyledCardHeader title={title} />
      <StyledCardContent>
        <Stack direction="column" justifyContent="center" minHeight={300} >
          {children}
        </Stack>
      </StyledCardContent>
    </StyledCard>
  )
}

function JoinPersonAvatar() {

  return (
    <Stack direction="row" justifyContent="center">
      <Avatar aria-label="Join Group" sx={{ width: 120, height: 120 }}>
        <PersonIcon sx={{ width: 120, height: 120, backgroundColor: '#CBD5EB' }} />
      </Avatar>
    </Stack>
  )
}

function JoinLinkMessage({ title, details }) {

  const headerLabel = "Sign Up"

  return (
    <JoinLinkCard title={
      <Stack direction="row" alignItems="center">
        <Icon sx={{ color: '#FFF' }}>
          <SchoolIcon />
        </Icon>
        <Typography variant="h6" ml={2}>{headerLabel}</Typography>
      </Stack>
    }>
      <Stack direction='column' height="100%" spacing={4}>
        <Typography variant='h4' color="primary" align="center" sx={{ textTransform: 'capitalize' }}>{title}</Typography>
        <JoinPersonAvatar />
        <Typography variant="h6" align="center" sx={{ color: (theme) => theme.palette.blue.main }}>{details}</Typography>
      </Stack>
    </JoinLinkCard >
  )
}

function JoinLinkMessageWithFinish({ title, details, onClick }) {
  const headerLabel = "Sign Up"

  return (
    <JoinLinkCard title={
      <Stack direction="row" alignItems="center">
        <Icon sx={{ color: '#FFF' }}>
          <SchoolIcon />
        </Icon>
        <Typography variant="h6" ml={2}>{headerLabel}</Typography>
      </Stack>
    }>
      <Stack direction='column' height="100%" spacing={4}>
        <Typography variant='h4' color="primary" align="center" sx={{ textTransform: 'capitalize' }}>{title}</Typography>
        <JoinPersonAvatar />
        <Typography variant="h6" align="center" sx={{ color: (theme) => theme.palette.blue.main }}>{details}</Typography>
        <StyledButton variant="contained" onClick={onClick}>Finish</StyledButton>
      </Stack>
    </JoinLinkCard >)
}



export { JoinLinkCard, JoinPersonAvatar, JoinLinkMessage, JoinLinkMessageWithFinish }