import React from 'react';
import { useLocation } from 'react-router-dom';
import HomeHeader from './HomeHeader';
import AppHeader from './AppHeader';

import './Header.css';

import { NO_AUTH_ROUTES } from '../../../App';
import { useAtomValue } from 'jotai';
import { workspaceTypeAtom } from '../../../store/workspaceAPI';
import { hasSchoolPracticeRoleAtom } from '../../../store/userRolesAPI';
import { isAuthenticatedAtom } from '../../../store/authTokenAtom';

function Header() {
    const location = useLocation();

    const workspaceType = useAtomValue(workspaceTypeAtom);

    const hasSchoolPracticeRole = useAtomValue(hasSchoolPracticeRoleAtom)

    const isAuthenticated = useAtomValue(isAuthenticatedAtom);

    if (isAuthenticated && (workspaceType === "student"  && !hasSchoolPracticeRole)|| workspaceType === "teacher") {
      return null;
    }

    const isOpenRoute = (NO_AUTH_ROUTES.indexOf(location.pathname) !== -1)
    || location.pathname.startsWith("/exampleexercise")
    || location.pathname.startsWith("/sign-out")
    || location.pathname.startsWith("/sign-in-school-practice")
    || location.pathname.startsWith("/join");

    return (
      <header>
          {isOpenRoute && <HomeHeader />}
          {!isOpenRoute && <AppHeader/>}
      </header>
    );
}

export default Header;
