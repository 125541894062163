import React from 'react';

import AppErrorMesage from './AppErrorMessage';

function InvalidCredentialsMessage() {
  return (
    <AppErrorMesage message="The password you entered is incorrect.Please try again." />
  );
}

export default InvalidCredentialsMessage;