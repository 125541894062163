import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import JoinEmailPanel from '../subpanels/JoinEmailPanel';
import JoinLoginPanel from '../subpanels/JoinLoginPanel';
import JoinSignupPanel from '../subpanels/JoinSignupPanel';

import { JoinLinkMessage, JoinLinkMessageWithFinish } from '../blocks/JoinStylingComponents';
import { JoinNetworkErrorPanel } from '../blocks/JoinErrorPanels';

const EMAIL_PANEL = 100;
const LOGIN_PANEL = 200;
const SIGNUP_PANEL = 300;
const JOIN_SUMMARY_PANEL = 400;

const JOIN_NETWORK_ERROR = 5000;
const JOIN_TRIAL_GROUP_PANEL = 5100;
const JOIN_CLOSED_GROUP_PANEL = 5200;
const JOIN_ACCOUNT_SUSPENDED_PANEL = 5300;

function reducer(state, action) {
  switch (action.type) {
    case 'error/network': return {
      ...state,
      activePanel: JOIN_NETWORK_ERROR,

      usernameErrorMessage: '',
      emailErrorMessage: '',
      passwordErrorMessage: '',
    }
    case 'account/found': return {
      ...state,
      activePanel: LOGIN_PANEL,

      usernameErrorMessage: '',
      emailErrorMessage: '',
      passwordErrorMessage: '',
    }
    case 'account/not-found': return {
      ...state,
      activePanel: SIGNUP_PANEL,

      usernameErrorMessage: '',
      emailErrorMessage: '',
      passwordErrorMessage: ''
    }
    case 'account/suspended': return {
      ...state,
      activePanel: JOIN_ACCOUNT_SUSPENDED_PANEL,

      usernameErrorMessage: '',
      emailErrorMessage: '',
      passwordErrorMessage: '',
    }
    case 'email/invalid-for-group': return {
      ...state,
      activePanel: EMAIL_PANEL,
      emailErrorMessage: action.emailErrorMessage
    }
    case 'email/validate-email': return {
      ...state,
      activePanel: EMAIL_PANEL,
      emailErrorMessage: action.emailErrorMessage,
      usernameErrorMessage: '',
      passwordErrorMessage: ''
    }
    case 'email/set-email': return {
      ...state,
      email: action.email,
      emailErrorMessage: ''
    }
    case 'email/email-panel': return {
      ...state,
      activePanel: EMAIL_PANEL,

      usernameErrorMessage: '',
      emailErrorMessage: '',
      passwordErrorMessage: ''
    }
    case 'password/validate-password': return {
      ...state,
      passwordErrorMessage: action.passwordErrorMessage
    }
    case 'password/set-password': return {
      ...state,
      password: action.password,
      passwordErrorMessage: ''
    }
    case 'password/incorrect': return {
      ...state,
      passwordErrorMessage: action.passwordErrorMessage
    }
    case 'group/trial-group': return {
      ...state,
      activePanel: JOIN_TRIAL_GROUP_PANEL
    }
    case 'username/set-username': return {
      ...state,
      username: action.username,
      usernameErrorMessage: ''
    }
    case 'username/validate-username': return {
      ...state,
      usernameErrorMessage: action.usernameErrorMessage
    }
    case 'username/inuse': return {
      ...state,
      usernameErrorMessage: action.usernameErrorMessage
    }
    case 'join/summary': return {
      ...state,
      activePanel: JOIN_SUMMARY_PANEL,
      user: action.user
    }
  }
}

function getInitialPanel(groupOpenUntil) {
  if (groupOpenUntil === undefined || groupOpenUntil === '') {
    return EMAIL_PANEL;
  }
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  if (new Date(groupOpenUntil) < now) {
    return JOIN_CLOSED_GROUP_PANEL;
  } else {
    return EMAIL_PANEL;
  }
}

function JoinPageMainPanel({ groupId, groupName, groupCode, groupOpenUntil }) {

  const navigate = useNavigate();

  const INITIAL_STATE = {
    activePanel: getInitialPanel(groupOpenUntil),
    email: '',
    password: '',
    username: '',
    usernameErrorMessage: '',
    emailErrorMessage: '',
    passwordErrorMessage: '',
  }

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const accountFound = () => dispatch({ type: 'account/found' });

  const accountNotFound = () => dispatch({ type: 'account/not-found' });

  const accountSuspendedGotoPanel = React.useCallback(() => dispatch(
    {
      type: 'account/suspended'
    }), []);

  const emailInvalidForGroup = () => {
    dispatch(
      {
        type: 'email/invalid-for-group',
        emailErrorMessage: "Please use your institution's email address"
      });
  }

  const joinTrialGroup = () => dispatch({ type: 'group/trial-group' });

  const emailGotoPanel = () => dispatch({ type: 'email/email-panel' });

  const joinSummaryGotoPanel = () => {
    dispatch(
      {
        type: 'join/summary',
      });
  }

  const emailOnChange = ({ target }) => dispatch(
    {
      type: 'email/set-email',
      email: target.value,
    });

  const passwordOnChange = ({ target }) => dispatch(
    {
      type: 'password/set-password',
      password: target.value,
    });

  const usernameOnChange = ({ target }) => dispatch(
    {
      type: 'username/set-username',
      username: target.value,
    });

  const incorrectCredentials = React.useCallback(() => dispatch(
    {
      type: 'password/incorrect',
      passwordErrorMessage: 'Wrong password. Try again or click Forgot password to reset it.'
    }), []);

  const usernameInUse = React.useCallback(() => dispatch(
    {
      type: 'username/inuse',
      usernameErrorMessage: 'That username is taken. Try another.'
    }), []);


  const emailValidate = () => {
    let errorMessage = '';
    const email = state.email;

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValidEmail = re.test(String(email).toLowerCase());
    if (!email || email === "") {
      errorMessage = "Provide your email address."
    } else if (!isValidEmail) {
      errorMessage = "The provided email address is invalid."
    }

    dispatch(
      {
        type: 'email/validate-email',
        emailErrorMessage: errorMessage
      });

    return errorMessage;
  }

  const passwordValidate = () => {
    let errorMessage = '';

    const password = state.password;

    if (!password || password === "") {
      errorMessage = "Provide your password."
    }

    if (password.length < 8) {
      errorMessage = "Password too short."
    }

    dispatch(
      {
        type: 'password/validate-password',
        passwordErrorMessage: errorMessage
      });

    return errorMessage;
  }

  const usernameValidate = () => {
    let errorMessage = '';

    const username = state.username;

    if (!username || username === "") {
      errorMessage = "Provide your username."
    }

    if (username.length < 8) {
      errorMessage = "Username too short."
    }

    dispatch(
      {
        type: 'username/validate-username',
        usernameErrorMessage: errorMessage
      });

    return errorMessage;
  }

  const finishOnClick = () => {
    navigate('/ws');
  }

  const networkErrorGotoPanel = React.useCallback(() => dispatch(
    {
      type: 'error/network'
    }), []);

  return (
    <>
      {state.activePanel === JOIN_CLOSED_GROUP_PANEL && (
        <JoinLinkMessage
          title="This group is closed"
          details="Ask your teacher for a new join link."
        />)
      }
      {state.activePanel === EMAIL_PANEL && (
        <JoinEmailPanel
          groupId={groupId}
          email={state.email}
          emailOnChange={emailOnChange}
          emailValidate={emailValidate}
          emailErrorMessage={state.emailErrorMessage}

          accountFound={accountFound}
          accountNotFound={accountNotFound}
          networkErrorGotoPanel={networkErrorGotoPanel}
          emailInvalidForGroup={emailInvalidForGroup}
          joinTrialGroup={joinTrialGroup}
        />)
      }
      {state.activePanel === JOIN_TRIAL_GROUP_PANEL && (
        <JoinLinkMessage
          title="Cannot join this group"
          details="Ask your teacher for a new join link."
        />)
      }
      {state.activePanel === JOIN_ACCOUNT_SUSPENDED_PANEL && (
        <JoinLinkMessage
          title="Account suspended."
          details="Contact us at recover@enlighted.app to recover your account."
        />)
      }
      {state.activePanel === JOIN_NETWORK_ERROR && (<JoinNetworkErrorPanel />)}
      {state.activePanel === LOGIN_PANEL && (
        <JoinLoginPanel
          groupId={groupId}
          email={state.email}
          password={state.password}
          passwordOnChange={passwordOnChange}
          passwordValidate={passwordValidate}
          passwordErrorMessage={state.passwordErrorMessage}

          emailGotoPanel={emailGotoPanel}
          joinSummaryGotoPanel={joinSummaryGotoPanel}
          accountSuspendedGotoPanel={accountSuspendedGotoPanel}
          networkErrorGotoPanel={networkErrorGotoPanel}

          incorrectCredentials={incorrectCredentials} />)
      }
      {state.activePanel === SIGNUP_PANEL && (
        <JoinSignupPanel
          groupCode={groupCode}
          username={state.username}
          email={state.email}
          password={state.password}

          usernameOnChange={usernameOnChange}
          usernameValidate={usernameValidate}
          usernameErrorMessage={state.usernameErrorMessage}
          passwordOnChange={passwordOnChange}
          passwordValidate={passwordValidate}
          passwordErrorMessage={state.passwordErrorMessage}

          emailGotoPanel={emailGotoPanel}
          joinSummaryGotoPanel={joinSummaryGotoPanel}
          networkErrorGotoPanel={networkErrorGotoPanel}
          usernameInUse={usernameInUse} />)
      }
      {state.activePanel === JOIN_SUMMARY_PANEL && (
        <JoinLinkMessageWithFinish
          title="Signup successful"
          details={`You are now part of group ${groupName}`}
          onClick={finishOnClick}
        />)
      }
    </>
  )

}

export default JoinPageMainPanel;