
import { Stack, styled } from '@mui/material';

const LandingPage = styled(Stack)({
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,
  padding: 0,
  backgroundColor: '#EFEFEF',
  overflow: 'hidden',
})

export default LandingPage;