import React from 'react'

import AppErrorMesage from './AppErrorMessage';

function AccountSuspendedMessage() {
  return (
    <AppErrorMesage message={
      "Account suspended. Please write to support@enlighted.app to recover it."
    } />);
}

export default AccountSuspendedMessage;