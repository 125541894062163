import React from 'react';

import LandingPage from '../../../components/core/app-layout/LandingPage';

import SigninForm from './blocks/SigninForm';

function SigninLandingPage() {
  return (
    <LandingPage>
        <SigninForm />
    </LandingPage>
  )
}

export default SigninLandingPage;
