import React from 'react'

import AppErrorMesage from './AppErrorMessage';

function LoginWithSurfMessage() {
  return (
    <AppErrorMesage message="Please log in with SURF." />
  );
}

export default LoginWithSurfMessage