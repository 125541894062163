import React from 'react';

import { Box, Button, Stack, Typography, styled } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { ID_SIGNUP_LINK_FROM_SIGN_IN } from './SigninForm';

const SinginTitle = styled(Typography)(({ theme }) => ({
  color: '#0000008a',
  fontWeight: 500,
  fontSize: theme.typography.h6.fontSize,
}));


const DividerLine = styled(Box)({
  color: '#282828',
  webkitFontSmoothing: 'antialiased',
  textAlign: 'center',
  font: 'inherit',
  border: 0,
  margin: 0,
  padding: 0,
  fontSize: '100%',
  height: '1px',
  backgroundColor: '#f1eeea',
  width: '100%',
  display: 'block'
});

const DividerLineText = styled(Box)({
  webkitFontSmoothing: 'antialiased',
  textAlign: 'center',
  font: 'inherit',
  border: 0,
  margin: 0,
  verticalAlign: 'baseline',
  display: 'inline-block',
  backgroundColor: '#ffffff',
  padding: '5px 10px',
  fontSize: 14,
  top: -16,
  position: 'relative',
  color: '#282828',
});

const StyledSignUpButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.2),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRadius: 10,
}));


function HorizontalLineWithOr() {
  return (
    <Box my={{ xs: 1, md: 2 }} mx={5}>
      <DividerLine>
        <DividerLineText>or</DividerLineText>
      </DividerLine>
    </Box>
  );
}

function EmptySignInFooter() {
  return (
    <Box height="2rem" />
  );
}

function SignUpFooter() {

  const navigate = useNavigate();

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} sx={{ width: '100%', pr: 4 }}>
      <SinginTitle
        variant="subtitle1"
        align="center"
        color="textSecondary">
        New to Enlight Ed?
      </SinginTitle>
      <StyledSignUpButton variant="text" color="primary" onClick={() => navigate("/sign-up2")} id={ID_SIGNUP_LINK_FROM_SIGN_IN}>
        Sign up
      </StyledSignUpButton>
    </Stack>
  );
}

export { HorizontalLineWithOr, EmptySignInFooter, SignUpFooter }