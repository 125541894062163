import React, { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useSetAtom } from 'jotai';

import { Box, Button, Icon, IconButton, InputAdornment, Stack, TextField, Typography, styled } from '@mui/material';

import { ID_JOIN_BACK_TO_EMAIL, JoinLinkCard, JoinPersonAvatar } from '../blocks/JoinStylingComponents';
import PasswordTextField from '../blocks/PasswordTextField';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';


import Loading from '../../../../components/core/data-fetch/Loading';

import { authTokenAtom } from '../../../../store/authTokenAtom';


const SIGNUP_AND_JOIN_GROUP_MUTATION = gql`
  mutation SignupAndJoinGroup($joinCode: String!, $username: String!, $email: String!, $password: String!) {
    signup_and_join_group(joinCode: $joinCode, username: $username, email: $email, password: $password) {
      token
    }
  }
`;


const UnderLinedAnchor = styled(`a`)({
  textDecorationLine: 'underline'
});


const StyledButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  backgroundColor: theme.palette.blue.main,
  '&:hover': {
    backgroundColor: theme.palette.blue.dark
  }
}));


function JoinSignupPanel({ username, email, password, groupCode, emailGotoPanel, usernameOnChange, usernameValidate, usernameErrorMessage, passwordOnChange, passwordValidate, passwordErrorMessage, joinSummaryGotoPanel, usernameInUse, networkErrorGotoPanel }) {

  const [signup, { loading, error }] = useMutation(SIGNUP_AND_JOIN_GROUP_MUTATION, {
    variables: {
      joinCode: groupCode,
      username,
      email,
      password
    },
    onCompleted: (data) => handleSignupCompleted(data)
  });

  useEffect(() => {
    if (error && error.graphQLErrors && error.graphQLErrors.length) {
      error.graphQLErrors.map(({ code }) => {
        switch (code) {
          case 4000:
            usernameInUse();
            break;
          default:
            networkErrorGotoPanel();
        }
      })
    }
  }, [error, usernameInUse, networkErrorGotoPanel]);

  const setAuthToken = useSetAtom(authTokenAtom);


  const handleSignupCompleted = (data) => {
    const { token } = data.signup_and_join_group;

    setAuthToken(token);

    joinSummaryGotoPanel();

  }

  const internalPasswordValidate = (event) => {
    const errorMessage = '';
    if (event && event.relatedTarget) {
      const eventId = event.relatedTarget.id;
      if (ID_JOIN_BACK_TO_EMAIL === eventId) {
        return errorMessage;
      }
    }
    return passwordValidate();
  }

  const internalUsernameValidate = (event) => {
    const errorMessage = '';
    if (event && event.relatedTarget) {
      const eventId = event.relatedTarget.id;
      if (ID_JOIN_BACK_TO_EMAIL === eventId) {
        return errorMessage;
      }
    }
    return usernameValidate();
  }

  const handleSignup = () => {
    let errorMessage = '';
    errorMessage = usernameValidate();
    if (errorMessage === '') {
      errorMessage = passwordValidate();
    }
    if (errorMessage === '') {
      signup();
    }
  }

  if (loading) return <Loading />;

  return (
    <JoinLinkCard
      title={
        <Box sx={{ position: 'relative' }}>
          <IconButton onClick={emailGotoPanel} id={ID_JOIN_BACK_TO_EMAIL} sx={{ maxWidth: 200, position: 'absolute', }}>
            <ArrowBackIcon fontSize="large" sx={{ color: '#FFF', position: 'absolute', top: 1, bottom: 1, left: 5 }} />
          </IconButton>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Icon sx={{ color: '#FFF' }}>
              <SchoolIcon />
            </Icon>
            <Typography variant="h6" ml={2}>Sign Up As New User</Typography>
          </ Stack>
        </Box>
      }
    >
      <JoinPersonAvatar />
      <Stack direction='row' justifyContent="center" spacing={1} m={2}>
        <EmailIcon color="primary" />
        <Typography variant="subtitle2" color="primary" >{email}</Typography>
      </Stack>
      <Stack direction='column'>
        <TextField
          sx={{ m: 2 }}
          placeholder="username"
          type="text"
          value={username}
          error={usernameErrorMessage !== ''}
          onChange={usernameOnChange}
          onBlur={internalUsernameValidate}
          autoFocus
          helperText={usernameErrorMessage ? usernameErrorMessage : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            )
          }}
        />
        <PasswordTextField
          password={password}
          errorMessage={passwordErrorMessage}
          onChange={passwordOnChange}
          validate={internalPasswordValidate}
          autoFocus={false}
        />
        <Typography variant="body1" sx={{ p: 1, color: '#0000008a' }}>
          By clicking Sign Up you agree to the Enlight Ed&#39;s <UnderLinedAnchor target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1EMb7nfom6cGH1vK8U5o6Tg6BN6mfLu8Q/view">Terms and Conditions </UnderLinedAnchor> and <UnderLinedAnchor target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1gQ1Rw71J5ryMikay1Uxc8Zna5Mkip3dx/view"> Privacy Policy</UnderLinedAnchor>.
        </Typography>
        <StyledButton variant="contained" onClick={handleSignup} sx={{ color: '#FFF' }}>Sign up</StyledButton>
      </Stack>
    </JoinLinkCard >)
}

export default JoinSignupPanel;