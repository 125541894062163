
import React from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import { Grid, Paper, Typography, styled } from '@mui/material';

const RedErrorIcon = styled(ErrorIcon)(() => ({
  color: '#c1272d',
}));


const MessageContainer = (styled)(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  maxWidth: "600px",
}));

const StyledTypography = styled(Typography)(() => ({
  color: "#c1272d",
  textAlign: "center",
}));

function AppErrorMesage({ message }) {
  return (
    <MessageContainer elevation={1}>
      <Grid container direction="row" justifyContent="center" spacing={2}>
        <Grid item>
          <RedErrorIcon />
        </Grid>
        <Grid item>
          <StyledTypography variant='body1' component="p">{message}</StyledTypography>
        </Grid>
      </Grid>
    </MessageContainer>
  );
}


export default AppErrorMesage;