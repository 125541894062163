import React, { useContext } from 'react';

import { useAtomValue } from 'jotai';

import { NavLink } from 'react-router-dom';

import { Container, Grid, Toolbar, Typography } from '@mui/material';

import { authorSandboxIdAtom } from '../../../store/userAPI';
import { workspaceTypeAtom } from '../../../store/workspaceAPI';
import { hasAnalyticsRoleAtom, hasSchoolPracticeRoleAtom } from '../../../store/userRolesAPI';
import { currentSubjectIdAtom } from '../../../store/subjectAPI';
import { isAuthenticatedAtom } from '../../../store/authTokenAtom';

import { Signout } from '../../../features/auth/signout/Signout';

import { ApplicationContext } from '../context/ApplicationContext';

import TestExercise from '../../exercise-overview/actions/TestExercise';

import { buildExerciseListLink } from './utils';
import useWindowSize from '../hooks/useWindowSize';
import { isMobile } from '../mobile/utils';
import SubjectSelector from '../../exercise-overview/actions/SubjectSelector';

import StyledAppBar from './StyledAppBar';

function AppHeader() {

  const windowSize = useWindowSize();

  const isMobileView = isMobile(windowSize);

  const appContext = useContext(ApplicationContext);
  const { exerciseId, isInEditor } = appContext;

  const isAuthenticated = useAtomValue(isAuthenticatedAtom);

  const workspaceType = useAtomValue(workspaceTypeAtom);

  const hasAnalyticsRole = useAtomValue(hasAnalyticsRoleAtom);

  const currentSubjectId = useAtomValue(currentSubjectIdAtom);

  const hasSchoolPracticeRole = useAtomValue(hasSchoolPracticeRoleAtom);

  const authorSandboxId = useAtomValue(authorSandboxIdAtom)


  const exercisesLinkDest = buildExerciseListLink(authorSandboxId, appContext, currentSubjectId);
  const propTabIdx = 0;

  return (
    <StyledAppBar position="static" sx={{ backgroundColor: '#515151' }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Grid container justifyContent="center" alignItems="center" >
            <Grid container item xs={2} justifyContent="center">
              {isAuthenticated &&
                (!hasSchoolPracticeRole) && (!isMobileView) && (workspaceType !== "teacher") && (workspaceType !== "student") && (
                  <NavLink to={exercisesLinkDest} style={{ textDecoration: 'none', height: '100%' }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>Exercises</Typography>
                  </NavLink>
                )}
            </Grid>
            <Grid container item xs={2} justifyContent="center">
              {isInEditor &&
                <TestExercise exerciseId={exerciseId} />
              }
              {(!isInEditor && exerciseId && '' !== exerciseId) &&
                <NavLink to={`/editor/${exerciseId}/${propTabIdx}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" sx={{ color: 'white' }}>Edit Exercise</Typography>
                </NavLink>
              }
            </Grid>
            <Grid item container xs={2} justifyContent="flex-end">
              {hasAnalyticsRole && (
                <NavLink
                  style={{ textDecoration: 'none' }}
                  to={`/reports/`}>
                  <Typography variant="h6" sx={{ color: 'white' }}>Reports</Typography>
                </NavLink>
              )}
            </Grid>
            <Grid container item xs={3} justifyContent="center">
              {isAuthenticated &&
                (workspaceType === 'author' || workspaceType === 'admin') && (
                  <NavLink to={`/gallery/`} style={{ textDecoration: 'none' }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>Image Gallery</Typography>
                  </NavLink>
                )}
            </Grid>
            <Grid item xs={1} justifySelf="center">
              {isAuthenticated &&
                (workspaceType === 'admin') && (
                  <SubjectSelector />
                )}
            </Grid>
            <Grid container item xs={2} justifyContent="flex-end" alignItems="center">
              <Signout inAppHeader />
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );

}

export default AppHeader;
