import React, { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';

import LandingPage from '../../../components/core/app-layout/LandingPage';

import Loading from '../../../components/core/data-fetch/Loading';

import { JoinLinkInvalidPanel, JoinNetworkErrorPanel } from '../join-link/blocks/JoinErrorPanels';
import { JOIN_INVALID_LINK_PANEL, JOIN_NETWORK_ERROR_PANEL, JOIN_PAGE_MAIN_PANEL } from '../join-link/JoinLandingPage';
import SPSigninPanel from './blocks/SPSigninPanel';


export const GET_GROUP_CARD_BY_CODE = gql`
    query GetGroupCardByCode($code: String!) {
      groupCardByCode(code: $code) {
        id
        name
        subjectName
        teacherName
        openFrom
        openUntil
      }
    }
  `;


function SPSigninLandingPage() {

    const { code } = useParams();
    const joinCode = code !== undefined && code !== "" ? code : " ";

    const { loading, error, data } = useQuery(
        GET_GROUP_CARD_BY_CODE,
        {
            variables: {
                code: joinCode
            },
            fetchPolicy: 'network-only',
            onCompleted: (data) => setGroupCard(data.groupCardByCode)
        }
    );

    const [panel, setPanel] = useState(JOIN_PAGE_MAIN_PANEL);
    const [groupCard, setGroupCard] = useState({});

    useEffect(() => {
        if (error && error.graphQLErrors && error.graphQLErrors.length) {
          error.graphQLErrors.map(({ code }) => {
            switch (code) {
                case 9100:
                    setPanel(JOIN_INVALID_LINK_PANEL);
                    break;
                default:
                    setPanel(JOIN_NETWORK_ERROR_PANEL);
            }
          })
        }
        if (!loading && !error && data) {
            setPanel(JOIN_PAGE_MAIN_PANEL);
        }
      }, [loading, error, data]);

    if (loading) return (<Loading/>);

    return (
        <LandingPage>
            {panel == JOIN_NETWORK_ERROR_PANEL && (<JoinNetworkErrorPanel />)}
            {panel == JOIN_INVALID_LINK_PANEL && (<JoinLinkInvalidPanel />)}
            {panel == JOIN_PAGE_MAIN_PANEL && (<SPSigninPanel groupCard={groupCard} joinCode={code}/>)}
        </LandingPage>
    )
}

export default SPSigninLandingPage;
