import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/browser';

import reportWebVitals from './reportWebVitals';

import { LicenseInfo } from '@mui/x-license-pro';

import { Provider } from 'jotai';

import { jotaiStore } from './store/store';
import { authTokenAtom } from './store/authTokenAtom';

import App from './App';


import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createLink } from 'apollo-v3-absinthe-upload-link';


const customFecth = (uri, options) => {
  const jotaiToken = jotaiStore.get(authTokenAtom)
  const token = jotaiToken === "" ? sessionStorage.getItem('authToken') : jotaiToken;
  options.headers = {
    ...options.headers,
    authorization: token ? `Bearer ${token}` : ''
  }
  return fetch(uri, options)
}

const httpLink = createLink({
  uri: '/graph',
  fetch: customFecth
});


const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
});

const ApolloApp = App => (
    <Provider store={jotaiStore}>
      <ApolloProvider client={client}>
          <App />
      </ApolloProvider>
    </Provider>
);

Sentry.init({
  dsn: 'https://e956ea0333ac4aa3914de517f4dfaff5@sentry.io/1529853',
});


LicenseInfo.setLicenseKey('36fa5a9835e1754367a93d1630b2f93cTz04MzE5OSxFPTE3MzgyMzgxMDkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  ApolloApp(App)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
