import { SvgIcon } from '@mui/material';
import React from 'react';

function AppHomeIcon(props) {
  return (
    <SvgIcon {...props} sx={{height:  {xs: 35, md: 55}, width: 'auto', color: '#515151'}}>

      <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="50 170 730 210.28" stroke="currentColor">
        <g>
          <polygon shapeRendering="optimizeQuality" strokeWidth="1px" style={{ fill: "#EF8323" }} points="146.66,290.26 147.96,298.02 100.34,314.36 93.26,305.81 146.51,289.85 	" />
          <g style={{ fill: "#FFF" }}>
            <path shapeRendering="optimizeQuality" strokeWidth="2px" d="M127.58,310.43l7.85,12.78c-3.49,2.16-7.24,3.88-11.24,5.16s-7.85,1.92-11.55,1.92c-7.49,0-13.99-1.49-19.48-4.46
			c-5.49-2.98-9.7-7.08-12.62-12.32c-2.93-5.24-4.39-11.24-4.39-18.01c0-6.36,1.64-12.21,4.93-17.55c3.28-5.34,7.75-9.57,13.4-12.7
			c5.64-3.13,11.8-4.7,18.48-4.7c8.83,0,16.17,2.59,22.02,7.78c5.85,5.18,9.75,12.5,11.7,21.94L96.48,307.2
			c1.74,2.67,4.05,4.72,6.93,6.16c2.87,1.44,6.05,2.16,9.55,2.16C117.77,315.51,122.65,313.82,127.58,310.43z M98.25,279.79
			c-3.44,3.8-5.16,8.73-5.16,14.78c0,1.13,0.05,2,0.15,2.62l34.18-12.16c-1.34-3.18-3.29-5.8-5.85-7.85
			c-2.57-2.05-5.9-3.08-10.01-3.08C106.12,274.09,101.68,276,98.25,279.79z"/>
            <path shapeRendering="optimizeQuality"strokeWidth="2px" d="M221.11,266.17c4.05,3.64,6.13,8.34,6.24,14.09v48.34H210.1v-42.19c-0.21-3.39-1.21-6.06-3-8.01
			c-1.8-1.95-4.49-2.98-8.08-3.08c-5.34,0-9.73,2.29-13.16,6.85c-3.44,4.57-5.16,10.39-5.16,17.47v28.95h-17.24v-65.13H179
			l1.23,11.55c2.36-4.52,5.77-8.03,10.24-10.55c4.46-2.51,9.62-3.77,15.47-3.77C212,260.7,217.05,262.52,221.11,266.17z"/>
            <path shapeRendering="optimizeQuality" d="M268.14,208.51V328.6H250.9V208.51H268.14z" />
            <path shapeRendering="optimizeQuality" d="M294,233.68c2.05-1.79,4.36-2.69,6.93-2.69c2.46,0,4.7,0.9,6.7,2.69c2,1.8,3,3.98,3,6.54c0,2.57-1,4.72-3,6.47
			c-2,1.75-4.23,2.62-6.7,2.62c-2.57,0-4.88-0.87-6.93-2.62c-2.05-1.74-3.08-3.9-3.08-6.47C290.92,237.66,291.95,235.48,294,233.68z
			 M308.94,263.47v65.13h-17.24v-65.13H308.94z"/>
            <path shapeRendering="optimizeQuality" strokeWidth="2px" d="M403.01,263.47v65.43c0,6.06-1.62,11.52-4.85,16.4c-3.23,4.87-7.75,8.72-13.55,11.55c-5.8,2.82-12.34,4.23-19.63,4.23
			c-7.08,0-12.99-1.1-17.71-3.31c-4.72-2.21-9.5-5.42-14.32-9.62l10.16-11.85c3.28,3.08,6.62,5.47,10.01,7.16s7.24,2.54,11.55,2.54
			c6.36,0,11.44-1.57,15.24-4.7c3.8-3.13,5.75-7.37,5.85-12.7v-10.93c-2.26,3.8-5.62,6.85-10.08,9.16s-9.68,3.46-15.63,3.46
			c-5.95,0-11.45-1.52-16.47-4.54c-5.03-3.03-9.03-7.18-12.01-12.47c-2.98-5.29-4.46-11.16-4.46-17.63c0-6.57,1.56-12.55,4.7-17.94
			c3.13-5.39,7.29-9.6,12.47-12.62c5.18-3.03,10.7-4.54,16.55-4.54c5.44,0,10.39,0.95,14.86,2.85c4.46,1.9,7.83,4.39,10.08,7.47
			l1.39-7.39H403.01z M378.14,312.43c3.64-2.46,6.18-5.8,7.62-10.01v-14.16c-1.44-4.1-4-7.39-7.7-9.85
			c-3.69-2.46-8.01-3.7-12.93-3.7c-5.85,0-10.75,1.92-14.7,5.77c-3.95,3.85-5.93,8.75-5.93,14.7c0,3.9,0.92,7.44,2.77,10.62
			c1.85,3.18,4.33,5.7,7.47,7.54c3.13,1.85,6.59,2.77,10.39,2.77C370.16,316.13,374.5,314.9,378.14,312.43z"/>
            <path shapeRendering="optimizeQuality" strokeWidth="2px" d="M484.22,266.17c4.05,3.64,6.13,8.34,6.24,14.09v48.34h-17.24v-42.19c-0.21-3.39-1.21-6.06-3-8.01
			c-1.8-1.95-4.49-2.98-8.08-3.08c-5.34,0-9.73,2.31-13.16,6.93c-3.44,4.62-5.16,10.42-5.16,17.4v28.95h-17.24V208.51h16.94v66.05
			c2.46-4.21,5.87-7.57,10.24-10.08c4.36-2.51,9.47-3.77,15.32-3.77C475.11,260.7,480.17,262.52,484.22,266.17z"/>
            <path shapeRendering="optimizeQuality" strokeWidth="2px" d="M537.57,234.83v28.64h16.94v13.55h-16.94v51.58h-17.24v-51.58h-11.24v-13.55h11.24v-28.64H537.57z" />
            <path shapeRendering="optimizeQuality" strokeWidth="2px" d="M666.43,310.43l7.85,12.78c-3.49,2.16-7.24,3.88-11.24,5.16s-7.85,1.92-11.55,1.92c-7.49,0-13.99-1.49-19.48-4.46
			c-5.49-2.98-9.7-7.08-12.62-12.32c-2.93-5.24-4.39-11.24-4.39-18.01c0-6.36,1.64-12.21,4.93-17.55c3.28-5.34,7.75-9.57,13.39-12.7
			c5.64-3.13,11.8-4.7,18.48-4.7c8.83,0,16.17,2.59,22.02,7.78c5.85,5.18,9.75,12.5,11.7,21.94l-50.19,16.94
			c1.74,2.67,4.05,4.72,6.93,6.16c2.87,1.44,6.05,2.16,9.55,2.16C656.63,315.51,661.5,313.82,666.43,310.43z M637.1,279.79
			c-3.44,3.8-5.16,8.73-5.16,14.78c0,1.13,0.05,2,0.15,2.62l34.18-12.16c-1.33-3.18-3.29-5.8-5.85-7.85
			c-2.57-2.05-5.9-3.08-10.01-3.08C644.98,274.09,640.54,276,637.1,279.79z"/>
            <path shapeRendering="optimizeQuality" strokeWidth="2px" d="M771.74,208.51V328.6H754.5v-8.78c-7.19,6.98-15.7,10.47-25.56,10.47c-6.06,0-11.52-1.44-16.4-4.31
			c-4.88-2.87-8.7-6.98-11.47-12.32c-2.77-5.34-4.16-11.55-4.16-18.63c0-7.08,1.46-13.24,4.39-18.48
			c2.92-5.23,6.98-9.21,12.16-11.93c5.18-2.72,11.06-4.08,17.63-4.08c4.72,0,9.19,0.9,13.4,2.69c4.21,1.8,7.59,4.23,10.16,7.31
			v-62.05H771.74z M747.11,311.97c3.49-2.46,5.95-5.9,7.39-10.32V287.8c-1.23-4-3.67-7.29-7.31-9.85c-3.64-2.56-7.88-3.85-12.7-3.85
			c-3.59,0-6.93,0.9-10.01,2.69c-3.08,1.8-5.54,4.26-7.39,7.39c-1.85,3.13-2.77,6.65-2.77,10.55c0,3.9,0.92,7.44,2.77,10.62
			c1.85,3.18,4.31,5.7,7.39,7.54s6.41,2.77,10.01,2.77C739.41,315.67,743.61,314.43,747.11,311.97z"/>
          </g>
        </g>
      </svg>

    </SvgIcon>
  )
}

export default AppHomeIcon;