
import React, { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';
import Loading from '../../../components/core/data-fetch/Loading';
import LandingPage from '../../../components/core/app-layout/LandingPage';

import JoinPageMainPanel from './layout/JoinPageMainPanel';

import { JoinLinkInvalidPanel, JoinNetworkErrorPanel } from './blocks/JoinErrorPanels';

export const GET_GROUP_BY_CODE = gql`
    query GetGroupByCode($code: String!) {
      groupByCode(code: $code) {
        id
        name
        openFrom
        openUntil
      }
    }
  `;


export const JOIN_PANEL_NOT_SET = -1;
export const JOIN_PAGE_MAIN_PANEL = 1000;
export const JOIN_NETWORK_ERROR_PANEL = 2000;
export const JOIN_INVALID_LINK_PANEL = 2100;

function JoinLandingPage() {

  const params = useParams();

  const { code } = params;
  const joinCode = code !== undefined && code !== "" ? code : " ";

  const { loading, error, data } = useQuery(
    GET_GROUP_BY_CODE,
    {
      variables: {
        code: joinCode
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => setGroup(data.groupByCode)
    }
  );

  const [panel, setPanel] = useState(JOIN_PANEL_NOT_SET);
  const [group, setGroup] = useState(undefined);

  useEffect(() => {
    if (error && error.graphQLErrors && error.graphQLErrors.length) {
      error.graphQLErrors.map(({ code }) => {
        switch (code) {
          case 9100:
            setPanel(JOIN_INVALID_LINK_PANEL);
            break;
          default:
            setPanel(JOIN_NETWORK_ERROR_PANEL);
        }
      })
    } else if (!loading && !error && data) {
      setPanel(JOIN_PAGE_MAIN_PANEL);
    }
  }, [loading, error, data]);

  if (loading) return (<Loading />);


  return (
    <LandingPage>
      {panel == JOIN_NETWORK_ERROR_PANEL && (<JoinNetworkErrorPanel />)}
      {panel == JOIN_INVALID_LINK_PANEL && (<JoinLinkInvalidPanel />)}
      {panel == JOIN_PAGE_MAIN_PANEL && (
        <JoinPageMainPanel
          groupId={group.id}
          groupName={group.name}
          groupCode={params.code}
          groupOpenUntil={group.openUntil} />)}
    </LandingPage>
  )
}

export default JoinLandingPage;