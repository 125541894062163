import { atom } from "jotai";
import { currentUserAtom } from "./internal/storedata";


const SETTING_KEY = "time_reporting";
const SETTING_CATEGORY = "chat";

export const isTimeTrackingEnabledAtom = atom(
  (get) => {
    const user = get(currentUserAtom);
    if (user == null) return false;

    if (user.hasOwnProperty("settings")) {
      const settings = user.settings;
      const settingElement = settings.find(s => s.key === SETTING_KEY && s.category === SETTING_CATEGORY);
      return settingElement ? settingElement.value === "true" : false;
    }
    return false;
  }
)

export const authorSandboxIdAtom = atom(
  (get) => {
    const currentUser = get(currentUserAtom);
    return currentUser !== null ? currentUser.editcategoryId : null;
  }
);

export const currentUserIdAtom = atom(
  (get) => {
    const currentUser = get(currentUserAtom);
    return currentUser !== null ? currentUser.id : null;
  }
);

export const currentUserDetailsAtom = atom(
  (get) => {
    const currentUser = get(currentUserAtom);
    if (currentUser === null) {
      return null;
    }

    const details = {
      id: currentUser.id,
      username: currentUser.username,
      email: currentUser.email,
      previousLoginTime: currentUser.previousLoginTime
    }

    return details;
  }
)