import React from 'react'

import { JoinLinkMessage } from './JoinStylingComponents';

function JoinNetworkErrorPanel() {
  return (<JoinLinkMessage
    title="Internal Server Error"
    details="The server could not complete your request. Please try again later."
  />);
}

function JoinLinkInvalidPanel() {
  return (<JoinLinkMessage
    title="The join link is invalid"
    details="Ask your teacher for a new link."
  />);
}

export { JoinLinkInvalidPanel, JoinNetworkErrorPanel }