import { atom } from "jotai";
import { focusAtom } from "jotai-optics";


export const EMPTY_STORE_DATA = {
  user: {},
  subjects: [{}],
  currentSubject: {},
  allTeacherGroups: [],
  currentTeacherGroups: null
}

export const storeDataAtom = atom(EMPTY_STORE_DATA);

export const currentUserAtom = focusAtom(storeDataAtom, (optic) => optic.prop('user'));



export const setupTeacherGroups = (groupCards, currentSubject) => {

  if (groupCards === null) return null;

  let gcd = groupCards.slice();


  if (currentSubject && currentSubject.id) {
    gcd = gcd.filter((c) => +(c.subjectId) === +(currentSubject.id) && c.memberType === 'student')
  }


  const result = gcd.sort((g1, g2) => {
    const d1 = new Date(g1.openUntil);
    const d2 = new Date(g2.openUntil);
    if (d1 > d2) {
      return -1;
    } else if (d1 === d2) {
      return 0;
    }
    return 1;
  });

  return result;
}
