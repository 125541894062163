import React from 'react';

export const ID_NOT_LOADED = -1;
export const ID_NOT_SET = -1;

const EMPTY_FUNCTION = () => { };

// In its current shape the default value only enumerates
// the fields, the ApplicationContextProvider is initialized at App level
export const defaultEditorData = {
    selectedLessonId: ID_NOT_SET,
    selectedLessonType: '',
    problemScrollIndex: -1,
    newlyAddedProblemId: ID_NOT_SET,

    exerciseId: ID_NOT_SET,

    isInEditor: false,
    signoutRedirect: '',

    isInTemplateView: false,

    setSelectedLessonId: EMPTY_FUNCTION,
    setSelectedLessonType: EMPTY_FUNCTION,
    setProblemScrollIndex: EMPTY_FUNCTION,

    setNewlyAddedProblemId: EMPTY_FUNCTION,

    setAuthoredExerciseContext: EMPTY_FUNCTION,
    setIsInEditor: EMPTY_FUNCTION,
    setSignoutRedirect: EMPTY_FUNCTION,

    setIsInTemplateView: EMPTY_FUNCTION,
};

export const ApplicationContext = React.createContext(
    defaultEditorData
);
