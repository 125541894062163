import { atomWithStorage, createJSONStorage, selectAtom } from "jotai/utils";
import { atom } from "jotai";

import { jotaiStore } from "./store";
import { focusAtom } from "jotai-optics";

import { getTestCenterUrl } from "../lib/test-center-utils";

/////////////////////////////////////////////////////////////////////////////////////////
///                            Implementation                                        ////
/////////////////////////////////////////////////////////////////////////////////////////

const testCenterPracticeStore = createJSONStorage(() => sessionStorage);

const INACTIVE_TEST_CENTER_PRACTICE_CONTEXT = {
  isActive: false,
  exercises: {
    practicedIds: [],
    notPracticedIds: [],
  },
  testType: '',
  testSize: 0,
}

const testCenterPracticeAtom = atomWithStorage('testCenterPractice', INACTIVE_TEST_CENTER_PRACTICE_CONTEXT, testCenterPracticeStore, { getOnInit: true });

const readOnlytestCenterPracticeAtom = atom((get) => get(testCenterPracticeAtom));

const settableExercisesAtom = focusAtom(testCenterPracticeAtom, (optic) => optic.prop('exercises'));

/////////////////////////////////////////////////////////////////////////////////////////
///                       Test Center Practice API                                   ////
/////////////////////////////////////////////////////////////////////////////////////////


export const startTestCenterPracticeMode = (testType, testSize, exerciseIds) => {
  jotaiStore.set(testCenterPracticeAtom, {
    isActive: true,
    testType: testType,
    testSize: testSize,
    exercises: {
      practicedIds: [],
      notPracticedIds: exerciseIds.slice(),
    }
  });
}

export const stopTestCenterPracticeMode = () => {
  jotaiStore.set(testCenterPracticeAtom, INACTIVE_TEST_CENTER_PRACTICE_CONTEXT);
}

export const markExerciseAsPracticed = (exerciseId) => {
  jotaiStore.set(settableExercisesAtom, (exercises) => {
    return {
      practicedIds: [...exercises.practicedIds, exerciseId],
      notPracticedIds: exercises.notPracticedIds.filter((id) => id !== exerciseId)
    }
  });
}

export const isTestCenterPracticeActiveAtom = selectAtom(readOnlytestCenterPracticeAtom, (s) => s.isActive);

export const nextExerciseIdForTestCenterPracticeAtom = selectAtom(readOnlytestCenterPracticeAtom, (s) => s.exercises.notPracticedIds.length > 0 ? s.exercises.notPracticedIds[0] : -1);

export const testCenterUrlAtom = selectAtom(readOnlytestCenterPracticeAtom, (s) => {
  return getTestCenterUrl(s.testSize, s.testType);
});

