import React, { useReducer, useState } from 'react'

import { gql, useQuery } from '@apollo/client';

import LandingPage from '../../../components/core/app-layout/LandingPage';
import Loading from '../../../components/core/data-fetch/Loading';


import Signup2Panel from './subpanels/Signup2Panel';
import { JoinNetworkErrorPanel } from '../join-link/blocks/JoinErrorPanels';

export const JOIN_INDIVIDUAL_PANEL = 1000;
export const JOIN_NETWORK_ERROR_PANEL = 2000;


const GET_FULL_ACCESS_SUBSCRIPTIONS = gql`
    query FullAccessSubscriptions($subject_id: Int!) {
        fullAccessSubscriptions(subject_id: $subject_id) {
            id
            subscription
            institute
      }
    }
  `;

function reducer(state, action) {
    switch (action.type) {
        case 'error/network': return {
            ...state,
            activePanel: JOIN_NETWORK_ERROR_PANEL,

            usernameErrorMessage: '',
            emailErrorMessage: '',
            passwordErrorMessage: '',
        }
        case 'email/set-email': return {
            ...state,
            email: action.email,
            emailErrorMessage: ''
        }
        case 'email/validate-email': return {
            ...state,
            emailErrorMessage: action.emailErrorMessage
        }
        case 'email/inuse': return {
            ...state,
            emailErrorMessage: action.emailErrorMessage
        }
        case 'password/validate-password': return {
            ...state,
            passwordErrorMessage: action.passwordErrorMessage
        }
        case 'password/set-password': return {
            ...state,
            password: action.password,
            passwordErrorMessage: ''
        }
        case 'username/set-username': return {
            ...state,
            username: action.username,
            usernameErrorMessage: ''
        }
        case 'username/validate-username': return {
            ...state,
            usernameErrorMessage: action.usernameErrorMessage
        }
        case 'username/inuse': return {
            ...state,
            usernameErrorMessage: action.usernameErrorMessage
        }
    }
}

function Signup2LandingPage() {

    const [fullAccessSubscriptions, setFullAccessSubscriptions] = useState([]);

    const { loading } = useQuery(
        GET_FULL_ACCESS_SUBSCRIPTIONS,
        {
            fetchPolicy: 'network-only',
            variables: {
                subject_id: 4
            },
            onCompleted: (data) => setFullAccessSubscriptions(data.fullAccessSubscriptions)
        }
    );

    const INITIAL_STATE = {
        panel: JOIN_INDIVIDUAL_PANEL,
        email: '',
        password: '',
        username: '',
        usernameErrorMessage: '',
        emailErrorMessage: '',
        passwordErrorMessage: '',
    }

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const emailOnChange = ({ target }) => dispatch(
        {
            type: 'email/set-email',
            email: target.value.toLowerCase(),
        });

    const passwordOnChange = ({ target }) => dispatch(
        {
            type: 'password/set-password',
            password: target.value,
        });

    const usernameOnChange = ({ target }) => dispatch(
        {
            type: 'username/set-username',
            username: target.value,
        });


    const usernameValidate = () => {
        let errorMessage = '';

        const username = state.username;

        if (!username || username === "") {
            errorMessage = "Provide your username."
        }

        if (username.length < 8) {
            errorMessage = "Username too short."
        }

        dispatch(
            {
                type: 'username/validate-username',
                usernameErrorMessage: errorMessage
            });
        return errorMessage;
    }

    const passwordValidate = () => {
        let errorMessage = '';

        const password = state.password;

        if (!password || password === "") {
            errorMessage = "Provide your password."
        }

        if (password.length < 8) {
            errorMessage = "Password too short."
        }

        dispatch(
            {
                type: 'password/validate-password',
                passwordErrorMessage: errorMessage
            });

        return errorMessage;
    }

    const emailValidate = () => {
        let errorMessage = '';

        const email = state.email;

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isValidEmail = re.test(String(email).toLowerCase());
        if (!email || email === "") {
            errorMessage = "Please provide an email address."
        } else if (!isValidEmail) {
            errorMessage = "The provided email address is invalid."
        }

        const isPaid = fullAccessSubscriptions.some(sub => email.endsWith(sub.institute));
        if (isPaid) {
            errorMessage = "Your institution has a license already. Please contact your teacher for a sign up link."
        }

        dispatch(
            {
                type: 'email/validate-email',
                emailErrorMessage: errorMessage
            });

        return errorMessage;
    }

    const usernameInUse =  React.useCallback(() => dispatch(
        {
            type: 'username/inuse',
            usernameErrorMessage: 'That username is taken. Try another.'
        }), []);

    const emailInUse =  React.useCallback(() => dispatch(
        {
            type: 'email/inuse',
            emailErrorMessage: 'That email is taken. Try another.'
        }), []);

    const networkErrorGotoPanel = React.useCallback(() => dispatch(
            {
                type: 'error/network'
            }), []);

    if (loading) return (<Loading/>)

    return (
        <LandingPage>
            {state.panel == JOIN_NETWORK_ERROR_PANEL && (<JoinNetworkErrorPanel />)}
            {state.panel === JOIN_INDIVIDUAL_PANEL &&
            (<Signup2Panel
                username={state.username}
                email={state.email}
                password={state.password}

                usernameOnChange={usernameOnChange}
                usernameValidate={usernameValidate}
                usernameErrorMessage={state.usernameErrorMessage}
                usernameInUse={usernameInUse}

                passwordOnChange={passwordOnChange}
                passwordValidate={passwordValidate}
                passwordErrorMessage={state.passwordErrorMessage}

                emailOnChange={emailOnChange}
                emailValidate={emailValidate}
                emailErrorMessage={state.emailErrorMessage}
                emailInUse={emailInUse}

                networkErrorGotoPanel={networkErrorGotoPanel}
            />)}
        </LandingPage>
    )
}

export default Signup2LandingPage;